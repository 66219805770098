export default [
  {
    "id": 0,
    "title": "Game Features & Test Announcement",
    "url": "game-features-and-test-announcement",
    "keywords": "",
    "description": "",
    "author": "",
    "topic": 0,
    "status": true,
    "content": 
    `  <h1>NORA’S ARK: MATCH 3 PUZZLE IS ALL ABOUT FUN CHALLENGES</h1>
      <p>Hey there, fellow Match 3 Puzzle lovers! Are you ready to embark on an extraordinary journey alongside Nora, a supernova-born hero with an unyielding spirit? Get ready to dive into the mystical world of Nora's Ark, where magic, friendship, and a dash of puzzling challenges await you!</p>
      <p>Novus, the unintentional designer of the Worlds, has created Nora, an unexpected hero blessed with cosmic powers and a heart as big as the universe. Little did she know that her destiny was entwined with a captivating planet called Earth, a place filled with beauty and wonder, but now facing a dire threat.</p>
      <img src="/assets/images/blogs/game-features-and-test-announcement-1.webp" loading="lazy"/>
      <p>As Nora lands on Earth, she encounters an adorable cat named Frank. Her new friend needs food, and other animals need different help. Nora offers what they need, and with her skillful Ark, she is willing to save the Earth. But be warned, some corruptive magic is seeping from certain corners of the Earth, posing a peril to its inhabitants.</p>
      <p>Fear not! Nora is here to save the day, and she's calling for your assistance! Your task is to match 3 or more same-colored stones in our thrilling Match 3 puzzle game. Swap, slide, and strategize to complete objectives before running out of moves. Guess what? The Campaign Mode in Nora’s Ark: Match 3 Puzzle is challenging and adrenaline-pumping. Be ready to use magical Power-ups against the Blockers you haven’t seen before in other Match 3 games.</p>
      <p>By mastering the art of swapping, you'll discover fantastic power-ups when you match four or more stones. These power stones will help you overcome obstacles and unleash their unique abilities with a swipe or a tap. There are also Boosters! We've got handy in-game Boosters to lend you a helping hand when things get tough. Don't worry if you don't have them, you can get them with Materia.</p>
      <img src="/assets/images/blogs/game-features-and-test-announcement-2.webp" loading="lazy"/>
      <p>Now, let's save some animals! Our Match 3 puzzle game is interconnected with Nora's mission to protect Earth's creatures. As you collect animals in the Match 3 levels, you'll place them on hexes in our enchanting Decoration meta game (or side game). There, you'll build a brand-new world, lovingly called "World," to ensure the animals' safety.</p>
      <img src="/assets/images/blogs/game-features-and-test-announcement-3.webp" loading="lazy"/>
      <p>The Seed Crystal at the heart of "World" is where it all begins. Surrounding it are empty areas, waiting for you to fill them with hexes, creating the perfect environment for the animals. As you place the animals on these hexes, neighboring areas will unveil themselves, as if a mystical fog lifts away.</p>
      <p>You have lots of resources to use! Placing hexes requires Gems, and to position animals, you'll need Materia. But don't fret – as you save animals and decorate the “World", your journey will unfold in sync with the Match-3 challenges.</p>
      <p>Sounds fun? Join Nora's Ark and let the magic of this match 3 puzzle game whisk you away to a fantastical realm of hope, companionship, and endless puzzles! Together, we'll save the Earth, protect its creatures, and create a sanctuary they can call home.</p>
      <img src="/assets/images/blogs/game-features-and-test-announcement-4.webp" loading="lazy"/>
      <p>Nora’s Ark: Match 3 Puzzle is on test in some Google Play and Apple AppStore regions. We will launch to the game in more countries soon. You can follow us on social media, or reach out to us for upcoming updates.</p>
      <p>The animals are counting on you, and so is Nora. Get ready to match, swap, and save the day – it's a puzzling paradise you won't want to miss!</p>
    `,
    "date": "25.07.2023"
  },
  {
    "id": 1,
    "title": "Follow Her Story on Social Media",
    "url": "follow-her-story-on-social-media",
    "keywords": "NorasArkGame, Match3Puzzle, NewPuzzleGame, GamingTok",
    "description": "",
    "author": "",
    "topic": 0,
    "status": true,
    "content": 
    `
      <h1>NORA ARRIVED! FOLLOW HER STORY ON SOCIAL MEDIA</h1>
      <p>Join us on an epic adventure with Nora's Ark: Match 3 Puzzle Game!</p>
      <p>Nora, a supernova-born hero, and her cosmic companion Ark have started a thrilling journey on a beautiful new planet for life to flourish: Earth! But darkness descends when an unexpected explosion rocks the surface, spreading corruption far and wide. As Nora investigates, she meets Frank, a hungry cat, and discovers a malevolent hooded figure behind the chaos. Determined to save her creation, Nora sets out on a heroic journey, helping animals find homes and using her extraordinary abilities to heal the dying planet. But the true identity of the mysterious figure remains shrouded in secrecy.</p>
      <p>Follow us on social media to uncover the thrilling tale of Nora's Ark, where magic, courage, and the bonds of friendship will be put to the ultimate test.</p>
      <img src="/assets/images/blogs/follow-her-story-on-social-media-5.webp" loading="lazy"/>
      <p>TEASER ALERT! Nora’s world is a marvel to behold, until darkness disrupts this beautiful creation of Novus. Seeking answers, Nora descends into the troubled realm where she encounters Frank, the hungry cat, and learns of a hooded figure spreading destruction. Determined to stop this sinister force, Nora aids struggling creatures like a capybara family, transporting them to her sanctuary world. But the forest's decay challenges her resolve, leading to an ultimate showdown with the hooded villain.</p>
      <p>Who is this mysterious figure, and can Nora overcome the darkness to save her cherished planet? Follow Nora's Ark on social media to unveil the thrilling tale and witness the rise of an enigmatic adversary.</p>
      <p>FOLLOW HER STORY ON SOCIAL MEDIA</p>
      
      <ul>
        <li><a href="https://www.tiktok.com/@norasarkgame" target="_blank">Nora’s Ark: Match 3 Puzzle on TikTok</a></li>
        <li><a href="https://www.instagram.com/norasarkgame" target="_blank">Nora’s Ark: Match 3 Puzzle on Instagram</a></li>
        <li><a href="https://www.facebook.com/norasarkgame" target="_blank">Nora’s Ark: Match 3 Puzzle on Facebook</a></li>
      </ul>
      
      <p>Like, share, and follow Nora's Ark for the latest updates, behind-the-scenes secrets, and exclusive sneak peeks of this enchanting puzzle game. Together, we'll uncover the truth behind the hooded figure, and with your help, Nora will brave the unknown, save innocent creatures, and protect her cherished creation. Spread the word to your friends and family, and let's embark on this magical journey together!</p>
    `,
    "date": "25.07.2023"
  }
]