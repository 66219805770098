import React, { FC } from 'react'
import { Link as LinkRRD } from "react-router-dom"
import { Box, Text } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import InterfaceBlog from "../interfaces/interfaceBlog"

interface Props {
  titles: string[]
  datas: InterfaceBlog[][]
  bigger?: boolean
  showLast6?: boolean
}

const SliderBlogs:FC<Props> = (props) => {

  const styles = {
    headerFontSize: {
      sm: props.bigger ? "18px" : "14px", 
      md: props.bigger ? "20px" : "16px", 
      "2xl": props.bigger ? "26px" : "22px"
    },
    rowH: {
      sm: props.bigger ? "32px" : "22px", 
      md: props.bigger ? "38px" : "28px", 
      "2xl": props.bigger ? "47px" : "37px"
    },
    rowFontSize: {
      sm: props.bigger ? "12px" : "8px", 
      md: props.bigger ? "14px" : "10px", 
      "2xl": props.bigger ? "16px" : "12px"
    },
  }
  
  return (
    <Box
      sx={{
        "&": {
          pos: "relative",
          h: "100%"
        },
        "& > .swiperBlogs": {
          pos: "initial",
          w: "100%",
          h: "100%",
          aspectRatio: props.showLast6 ? "auto" : "25/13",
          "& > .swiper-wrapper": {
            "& > .swiper-slide": {
              w: "100%",
              h: "100%",
              minH: "200px",
              overflow: "auto",
              "& > img": {
                h: "100%",
                w: "100%",
                objectFit: "cover"
              }
            }
          },
        }
      }}
    >
      <Swiper
        pagination={{
          clickable: true,
          el: ".swiperBlogs-swiper-pagination",
          renderBullet: function (index, className) {
            return `<div class="${className}"><p class="index">0${index+1}</p><p class="text">${props.titles[index]}</p></div>`
          }
        }}
        modules={[Pagination, Autoplay]}
        className="swiperBlogs"
        speed={400}
      >
        {
          props.datas.map((secondSliderCategories,i)=>
            <SwiperSlide key={i}>
              {
                props.showLast6 ? secondSliderCategories.sort((a,b)=>a > b ? 1 : -1).slice(-6).map((secondSliderItem, k)=>
                  <Box key={k} as={LinkRRD} to={`/blogs/${secondSliderItem.url}`} display="flex" justifyContent="space-between" alignItems="center" textDecoration="none!important" h={styles.rowH} bg={k % 2 === 0 ? "#ffffff22" : "transparent"} px={{sm: "6px", md: "14px", "2xl": "28px"}}>
                    <Box pr="10px">
                      <Text fontSize={styles.rowFontSize} color="#fff" noOfLines={1}>{secondSliderItem.title}</Text>
                    </Box>
                    <Box>
                      <Text fontSize={styles.rowFontSize} color="#fff">{secondSliderItem.date}</Text>
                    </Box>
                  </Box>
                )
                : secondSliderCategories.map((secondSliderItem, k)=>
                <Box key={k} as={LinkRRD} to={`/blogs/${secondSliderItem.url}`} display="flex" justifyContent="space-between" alignItems="center" textDecoration="none!important" h={styles.rowH} bg={k % 2 === 0 ? "#ffffff22" : "transparent"} px={{sm: "6px", md: "14px", "2xl": "28px"}}>
                  <Box pr="10px">
                    <Text fontSize={styles.rowFontSize} color="#fff" noOfLines={1}>{secondSliderItem.title}</Text>
                  </Box>
                  <Box>
                    <Text fontSize={styles.rowFontSize} color="#fff">{secondSliderItem.date}</Text>
                  </Box>
                </Box>
                )
              }
            </SwiperSlide>
          )
        }
      </Swiper>
      <Box className="swiperBlogs-swiper-pagination"
        sx={{
          "&": {
            pos: "absolute",
            top: {sm: "-26px!important",md: "-32px!important", "2xl": "-38px!important"},
            h: "fit-content",
            display: "flex!important",
            alignItems: "center",
            justifyContent: "start",
            "& .swiper-pagination-bullet": {
              pos: "relative",
              display: "flex",
              alignItems: "center",
              opacity: "1",
              borderRadius: 0,
              bgColor: "transparent",
              w: "auto",
              h: {sm: "22px", "2xl": "30px"},
              ml: {sm: "28px!important", "2xl": "42px!important"},
              mr: "0!important",
              border: "1px solid transparent",
              "&:only-child":{
                display: "block!important"
              },
              "& .text": {
                whiteSpace: "nowrap",
                color: "#fff",
                fontSize: styles.headerFontSize,
                fontWeight: "300",
                lineHeight: "1",
                transition: "400ms",
              },
              "& .index": {
                pos: "absolute",
                left: {sm: "-16px", "2xl": "-20px"},
                top: "-5px",
                whiteSpace: "nowrap",
                fontSize: {sm: "14px", "2xl": "18px"},
                fontWeight: "300",
                lineHeight: "1",
                color: "transparent",
                transition: "400ms",
                "&::after": {
                  content: "''",
                  borderBottom: "1px solid transparent",
                  display: "block",
                  transform: "translate(8px, -4px) rotate(-45deg)",
                  transition: "400ms",
                }
              },
              "&.swiper-pagination-bullet-active": {
                transition: "400ms",
                "& .index": {
                  color: "#f8bf13",
                  "&::after": {
                    borderBottom: "1px solid #f8bf13",
                  }
                }
              },
            }
          },
        }}
      />
    </Box>
  )
}

export default SliderBlogs