import { FC } from "react"
import { Link as LinkRRD } from "react-router-dom"
import { Box, Button, Link, SystemStyleObject, Text } from "@chakra-ui/react"
import {FaLinkedin as LinkedinIcon} from "react-icons/fa"

interface Props{
  className?: string
  sx?: SystemStyleObject
}

const SectionCareers:FC<Props> = (props) => {
  return (
    <Box className={props.className} pos="relative" h="100vh" sx={props.sx}>
      <Box pos="absolute" left="50%" top="50%" sx={{transform: "translate(-50%, -50%)"}}>
        <Link as={LinkRRD} to="https://www.linkedin.com/company/lostctrlgames/jobs/" isExternal color="#fff" fontSize={{sm: "14px", lg: "16px", xl: "20px", "2xl": "28px"}} fontWeight="700" display="flex" alignItems="center" p={{sm: "5px 12.5px", xl: "7.5px 18.5px", "2xl": "10px 25px"}} borderRadius="75px" bg="#0a66c2" _hover={{bg: "#004182"}} _active={{transform: "scale(0.9)"}}>
          <LinkedinIcon/>
          <Text color="#fff">Easy Apply</Text>
        </Link>
      </Box>
    </Box>
  )
}

export default SectionCareers