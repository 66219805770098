import React, { FC, useEffect, useState } from "react"
import { Box, Img, Text } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"

const headerAnimation = {
  initial: {
    top: "0%",
    opacity: 0,
    transition: {duration: 0, delay: 0}
  },
  animate: {
    top: "4%",
    opacity: 1,
    transition: {duration: 0.25, delay: 0.6}
  }
}

const headerPageTextAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {duration: 0.3, delay: 0.3}
  },
  exit: {
    opacity: 0,
    transition: {duration: 0.3}
  }
}

const footerAnimation = {
  initial: {
    bottom: "0%",
    opacity: 0,
    transition: {duration: 0, delay: 0}
  },
  animate: {
    bottom: "4%",
    opacity: 1,
    transition: {duration: 0.25, delay: 0.6}
  }
}

interface Props {
  swiperItems: {name: string, link: string, section: React.ReactElement}[]
  swiperCurrentIndex: number
}

const Layout:FC<Props> = (props) => {

  const [swiperCurrentIndex, setSwiperCurrentIndex] = useState(props.swiperCurrentIndex)

  useEffect(()=>{      
    setSwiperCurrentIndex(props.swiperCurrentIndex)
  },[props.swiperCurrentIndex])
  
  return (
    <Box pos="fixed" top={0} left={0} w="100vw" h="100vh" pointerEvents="none" zIndex={15}>

      <Box pos="fixed" left={{sm: "5px", md: "10px"}} right={{sm: "5px", md: "10px"}} as={motion.div} variants={headerAnimation} initial="initial" animate={swiperCurrentIndex ? "animate" : "initial"}>
        <Box pos="absolute" left="0" w={{sm: "27.5%", md: "35%"}} display="flex" flexDir="row" justifyContent="start">
          <Box w="100%" borderBottom="1px solid #999"/>
        </Box>

        <Box pos="absolute" top={{sm: "-12.5px", md: "-20px"}} left="50%" w={{sm: "45%", md: "30%"}} display="flex" flexDir="column" sx={{transform: "translateX(-50%)"}}>
          <Box pos="relative" w="100%" h={{sm: "25px", md: "42.5px"}} display="flex" justifyContent="center">
            <AnimatePresence>
              <Text fontSize={{sm: "25px", md: "42px"}} textTransform="uppercase" fontWeight="300" lineHeight="1" whiteSpace="nowrap" pos="absolute" color="#fff"
                key={swiperCurrentIndex} as={motion.p} variants={headerPageTextAnimation} initial="initial" animate="animate" exit="exit"
              >{props.swiperItems[swiperCurrentIndex]?.name}</Text>
            </AnimatePresence>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" zIndex="1">
            <Box borderBottom="1px solid #f8bf13" w="20%"/>
            <Img src="assets/images/logo/logo3.svg" w={{sm: "30px", md: "45px"}} mx={{sm: "5px", md: "15px"}}/>
            <Box borderBottom="1px solid #f8bf13" w="20%"/>
          </Box>
        </Box>

        <Box pos="absolute" right="0" w={{sm: "27.5%", md: "35%"}} display="flex" flexDir="row" justifyContent="end">
          <Box w="67%" borderBottom="1px solid #999"/>
          <Box mx={{sm: "6px", md: "12.5px"}} display="flex" lineHeight="0">
            <Text textTransform="uppercase" color="#fff" fontSize={{sm: "9px", md: "11px"}} fontWeight="600" sx={{"&::after": {content: "'-'", mx: {sm: "4px", md: "11px"}}}}>Page</Text>
            <Box pos="relative" w={{sm: "12px", md: "16px"}}>
              <AnimatePresence>
                <Text textTransform="uppercase" color="#fff" fontSize={{sm: "9px", md: "11px"}} fontWeight="600" pos="absolute"
                  key={swiperCurrentIndex} as={motion.p} variants={headerPageTextAnimation} initial="initial" animate="animate" exit="exit"
                >
                  {swiperCurrentIndex > 0 && swiperCurrentIndex < 10 && "0"}{swiperCurrentIndex + 1}
                </Text>
              </AnimatePresence>
            </Box>
            <Text textTransform="uppercase" color="#fff" fontSize={{sm: "9px", md: "11px"}} mx={{sm: "4px", md: "11px"}} textAlign="center">/</Text>
            <Text textTransform="uppercase" color="#fff" fontSize={{sm: "9px", md: "11px"}} fontWeight="600">{swiperCurrentIndex > 0 && swiperCurrentIndex < 10 && "0"}{props.swiperItems.length - 1}</Text>
          </Box>
          <Box w="2.5%" borderBottom="1px solid #999"/>
        </Box>
      </Box>

      <Box pos="fixed" left={{sm: "5px", md: "10px"}} right={{sm: "5px", md: "10px"}} as={motion.div} variants={footerAnimation} initial="initial" animate={swiperCurrentIndex ? "animate" : "initial"}>
        <Box pos="absolute" left="0" borderTop="1px solid #999" w={{sm: "42.5%", md: "45%"}}/>

        <Box pos="absolute" bottom="-20px" left="50%" transform="translateX(-50%)" display="flex" alignItems="center">
          <Img src="assets/images/logo/logo2.svg" w={{sm: "30px", md: "42px"}}/>
        </Box>

        <Box pos="absolute" right="0" borderTop="1px solid #999" w={{sm: "42.5%", md: "45%"}}/>
      </Box>

    </Box>
  )
}

export default Layout