import { useState, useRef, useEffect } from "react"
import * as ReactDOMServer from "react-dom/server"
import {Helmet} from "react-helmet-async"
import { useLocation, useNavigate } from "react-router-dom"

import {Swiper, SwiperSlide} from "swiper/react"
import {Mousewheel, Pagination} from "swiper"
import "swiper/css"
import "swiper/css/pagination"

import { Box, IconButton, Text } from "@chakra-ui/react"
import { VscMenu as MenuIcon } from "react-icons/vsc"

import SectionIntro from "../components/sections/sectionIntro"
import SectionNews from "../components/sections/sectionNews"
import SectionGames from "../components/sections/sectionGames"
import SectionGameplay from "../components/sections/sectionGameplay"
import SectionAboutUs from "../components/sections/sectionAboutUs"
import SectionCareers from "../components/sections/sectionCareers"
import SectionContactUs from "../components/sections/sectionContactUs"
import SectionHeroes from "../components/sections/sectionHeroes"
import Layout from "../components/layout"
import Footer from "../components/sections/footer"

const swiperItems = [
  {name: "Home", link: "#home", section: <SectionIntro/>},
  {name: "News", link: "#news", section: <SectionNews/>},
  {name: "Games", link: "#games", section: <SectionGames/>},
  {name: "Gameplay", link: "#gameplay", section: <SectionGameplay/>},
  {name: "Heroes", link: "#heroes", section: <SectionHeroes/>},
  {name: "About Us", link: "#about-us", section: <SectionAboutUs/>},
  {name: "Careers", link: "#careers", section: <SectionCareers/>},
  {name: "Contact Us", link: "#contact-us", section: <SectionContactUs/>},
  {name: "Footer", link: "#footer", section: <Footer/>},
]

export default function HomePage() {  

  const location = useLocation()
  const navigate = useNavigate()
  const [swiperCurrentIndex, setSwiperCurrentIndex] = useState(0)  
  const [showNavbar, setShowNavbar] = useState(true)
  const swiperRef:any = useRef()

  let navbar:any = undefined
  const [navbarButtonHeight, setNavbarButtonHeight] = useState("16%")
  
  if(typeof document !== "undefined"){
    navbar = document.getElementsByClassName("main-swiper-pagination")
  }

  function collapseNavbar(){

    if(showNavbar){
      navbar[0].classList.add("hide")
    } else {
      navbar[0].classList.remove("hide")
    }
    
    setShowNavbar(!showNavbar)
  }
  
  return (
    <>
      <Helmet>
        <title>Lost CTRL | New Generation of Match 3</title>
        <meta name="description" content="Lost CTRL is a game studio which creates new generation match 3 games." />
      </Helmet>

      <Box
        sx={{
          "&": {
            background:"url('./assets/images/sections/bg.webp')", backgroundSize:"cover", backgroundRepeat:"no-repeat", h: "100vh",
          },
          "& > .main-swiper": {

          }
        }}
      >
        <Swiper
          className="main-swiper"
          direction="vertical"
          slidesPerView="auto"
          autoHeight={true}
          spaceBetween={0}
          speed={600}
          mousewheel={true}
          breakpoints={{
            300: {
              allowTouchMove: true
            },
            769: {
              allowTouchMove: false
            }
          }}
          pagination={{
            clickable: true,
            el: ".main-swiper-pagination",
            renderBullet: function (i, className){
              if(i !== (swiperItems.length - 1)){
                return ReactDOMServer.renderToStaticMarkup
                (
                  <Box className={className}>
                    <Text>{swiperItems[i]?.name}</Text>
                    <Box className="swiper-pagination-bullet-line"/> 
                  </Box>
                )
              } else {
                return ""
              }
            }
          }}
          modules={[Mousewheel, Pagination]}
          ref={swiperRef}
          onSlideChange={(swiper)=>{setSwiperCurrentIndex(swiper.activeIndex), navigate(swiperItems[swiper.activeIndex].link)}}
          initialSlide={swiperItems.findIndex(item=>item.link === location.hash)}
        >
          {
            swiperItems.map((swiperItem, i)=>
              <SwiperSlide key={i}>
                {swiperItem.section}
              </SwiperSlide>
            )
          }
        </Swiper>
        <Box
          sx={{
            pos: "absolute",
            left: 0,
            top: "50%!important",
            transform: "translateY(-50%)",
            zIndex: 10,
          }}
        >
          <Box mb="20px" ml={{sm: "5px!important", md: "10px!important"}}>
            <IconButton aria-label="button" icon={<MenuIcon/>} fontSize={{sm: "20px", "2xl": "30px"}} minW="auto" h="auto" bg="transparent" color="#fff" transition="600ms" _hover={{bg: "transparent"}} sx={{WebkitTapHighlightColor: "transparent", transform: showNavbar ? "rotate(0deg)" : "rotate(90deg)", "& path": {stroke: "#fff"}}} onClick={()=>collapseNavbar()}/>
          </Box>
          <Box className="main-swiper-pagination"
            sx={{
              "&":{
                pos: "relative",
                left: "initial!important",
                top: "initial!important",
                right: "initial!important",
                transform: "translateX(0)!important",
                textAlign: "left",
                transition: "600ms",
                borderLeft: "1px solid #999",
                py: "10px",
                ml:{sm: "5px!important", md: "10px!important"},
                "&.hide": {
                  transform: "translateX(-150%)!important"
                },
                "& > .swiper-pagination-bullet": {
                  pos: "relative",
                  fontSize: {sm: "10px", "2xl": "14px"},
                  fontWeight: "300",
                  display: "flex!important",
                  alignItems: "center",
                  cursor: "pointer",
                  w: "fit-content",
                  h: "22px",
                  my: {sm: "15px!important", "2xl": "25px!important"},
                  ml: "-2px!important",
                  bg: "none",
                  opacity: 1,
                  transition: "600ms",
                  textTransform: "uppercase",
                  userSelect: "none",
                  borderLeft: "0.3vh solid transparent",
                  borderRadius: 0,
                  "& > p":{
                    ml: "10px",
                    pos: "relative",
                    color: "#fff",
                    "&::after": {
                      content: "''",
                      pos: "absolute",
                      left: "0",
                      bottom: "-3px",
                      w: "0%",
                      h: "1px",
                      bgColor: "#f8bf13",
                      transition: "600ms",
                    }
                  },
                  "&.swiper-pagination-bullet-active":{
                    fontSize: {sm: "14px!important", "2xl": "18px!important"},
                    borderLeft: "3px solid #f8bf13",
                    "& > p::after": {
                      w: "100%"
                    }
                  },
                }
              },
            }}
          />
        </Box>
      </Box>

      <Box>
        <Layout swiperItems={swiperItems} swiperCurrentIndex={swiperCurrentIndex}/>
      </Box>

    </>
  )
}