export default function detectDevice(){

  if(typeof window !== "undefined"){
    const userAgent = window.navigator.userAgent

    if (/android/i.test(userAgent)) {
      return "android"
    } else if (/AppleWebKit/.test(userAgent)) {
      return "apple"
    } else {
      return "another"
    }
  }
  
}