import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { ChakraProvider } from "@chakra-ui/react"
import theme from "./chakra/theme"
import { HelmetProvider } from "react-helmet-async"

import HomePage from "./pages/homePage"
import BlogsPage from "./pages/blogsPage"
import BlogPage from "./pages/blogPage"
import TermsAndConditionsPage from "./pages/termsAndConditionsPage"
import PrivacyPolicyPage from "./pages/privacyPolicyPage"
import NavigateToStore from "./pages/navigateToStore"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />}/>
            <Route path="/blogs" element={<BlogsPage />}/>
            <Route path="/blogs/:blogName" element={<BlogPage />}/>
            <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />}/>
            <Route path="/navigating-to-store" element={<NavigateToStore />}/>
            <Route path="*" element={<Navigate to={"/"}/>}/>
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </ChakraProvider>
  </React.StrictMode>
)
