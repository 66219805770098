import { FC } from "react"
import { Link as LinkRRD } from "react-router-dom"
import { Box, Img, Link, SystemStyleObject, Text } from "@chakra-ui/react"
import {FaFacebookF as IconFacebook, FaTwitter as IconTwitter, FaYoutube as IconYoutube, FaDiscord as IconDiscord, FaInstagram as IconInstagram, FaLinkedin as IconLinkedin, FaTiktok as IconTiktok} from "react-icons/fa"

const socials = [
  {
    href: "https://www.tiktok.com/@norasarkgame",
    icon: IconTiktok,
    status: true
  },
  {
    href: "https://www.facebook.com/norasarkgame",
    icon: IconFacebook,
    status: true
  },
  {
    href: "https://www.instagram.com/lostctrlgames/",
    icon: IconInstagram,
    status: true
  },
  {
    href: "https://www.yusufcode.com",
    icon: IconTwitter,
    status: false
  },
  {
    href: "https://www.yusufcode.com",
    icon: IconYoutube,
    status: false
  },
  {
    href: "https://discord.gg/lostctrl",
    icon: IconDiscord,
    status: true
  },
  {
    href: "https://www.linkedin.com/company/lostctrlgames",
    icon: IconLinkedin,
    status: true
  },
]

interface Props{
  className?: string
  sx?: SystemStyleObject
}

const Footer:FC<Props> = (props) => {
  return (
    <Box className={props.className} h={{sm: "40vh", md: "30vh"}} pos="relative" sx={props.sx}>

      <Box mx="auto" w="fit-content" mb="20px">
        <Img src="/assets/images/logo/logo3.svg" h={{sm: "40px", "2xl": "55px"}} objectFit="cover"/>
      </Box>
      
      <Box mx="auto" w="fit-content" mb="10px">
        <Link as={LinkRRD} to="/privacy-policy" isExternal fontSize="15px" mr="10px" color="#fff">Privacy Policy</Link>
        <Link as={LinkRRD} to="/terms-and-conditions" isExternal fontSize="15px" color="#fff">Terms & Conditions</Link>
      </Box>
      
      <Box display="flex" mx="auto" w="fit-content">
        {
          socials.filter(a=>a.status === true).map((social,i)=>
            <Link key={i} as={LinkRRD} to={social.href} isExternal padding="7px" mx={{sm: 0, md: "5px"}} transition="0.1s" sx={{"& path": {transition:"0.1s"}}} _hover={{"& path": {color: "#fff"}}}>
              <Box as={social.icon} fontSize={{sm: "20px", "2xl": "25px"}} color="#ddd"/>
            </Link>
          )
        }
      </Box>
      
    </Box>
  )
}

export default Footer