import {useEffect} from "react"
import { useNavigate } from "react-router-dom"
import { Box } from "@chakra-ui/react"
import detectDevice from "../functions/detectDevice"

const NavigateToStore = () => {

  const navigate = useNavigate()

  useEffect(()=>{

    if (detectDevice() === "android") {
      window.location.replace("https://play.google.com/store/apps/details?id=com.LostCTRL.NorasArk.match.quest")
    }

    if (detectDevice() === "apple") {
      window.location.replace("https://apps.apple.com/tr/app/noras-ark-match-3-puzzle/id1621714900")
    }

  },[])
  
  return (
    <Box>
    </Box>
  )
}

export default NavigateToStore