import React from 'react'
import { Box } from '@chakra-ui/react'
import "../styles/privacyPolicyStyles.scss"
import { Helmet } from 'react-helmet-async'

const PrivacyPolicyPage = () => {
  return (
    <Box h="100vh" overflow="auto" p="25px" bg="#fff" className="privacyPolicy">
      
      <Helmet>
        <title>Lost CTRL | Privacy Policy</title>
      </Helmet>
      
      <p className="c0"><span className="c1">PRIVACY POLICY</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Effective Date: December 11, 2022</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <ol className="c2 lst-kix_list_1-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Introduction</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">As Lost CTRL Dijital Eglence ve Teknoloji A.S. (&ldquo;Lost CTRL&rdquo; or
            &ldquo;we&rdquo;, &ldquo;us&rdquo;) we design, develop, and publish mobile games we&rsquo;re passionate about. In
            accordance with the laws of the nations in which we conduct business, we attempt to clearly explain how and why we
            collect, retain, disclose, and utilize your personal data in this Privacy Policy. Any information that relates to
            a named, identifiable, and living individual is referred to as &ldquo;personal data&rdquo; in this Policy.</span>
        </p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">This privacy policy is applicable to all Lost CTRL websites, mobile applications,
            forums, blogs of every sort, and any other online or offline services that Lost CTRL offers. It also applies to
            all marketing and advertising campaigns we run across all platforms, as well as any additional services we might
            occasionally offer you. Our games, websites, marketing and advertising activities, and other services are
            collectively referred to as &ldquo;Services&rdquo; in this policy.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Once you download our mobile application, access and/or play our games, or interact
            with our websites or other Services, your personal data will be handled in accordance with this privacy policy in
            order to deliver the Services. You shouldn&rsquo;t use our games or other services if you have any additional
            worries about supplying information to us or about it being handled as specified in this Privacy Policy. This
            policy is meant to comply with international laws, including those in North America, Europe, Turkey, the
            Asia-Pacific region, and other nations.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <ol className="c2 lst-kix_list_2-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Gathering of Your Personal Information</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">We may get personal information directly from you or from other third parties that have
            your consent or another legal basis to share it with us (including advertising platforms and partners and data
            aggregators who have obtained). This may include details about you, other games and services you use, demographic
            data, and information about your geographical region. Your personal information will be used in accordance with
            this privacy policy.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">2.1 Personal Information Obtained Directly from You</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">By downloading, accessing, and/or playing our games or using our other Services, you
            authorize Lost CTRL to process your personal data in order to deliver the Services. The majority of this
            information is provided by you or your device directly, including your unique device ID (persistent or
            non-persistent), your device&rsquo;s operating system, version, model, CPU, amount of RAM and disk space, hardware
            type, Identifier For Advertising (IDFA), advertising ID, the version of your operating system (&ldquo;OS&rdquo;),
            your device name, game center ID, and your location determined by your Internet Protocol address
            (&ldquo;IP&rdquo;). We use this information to deliver our games and Services to you, including for optimization,
            in-game offer customization, and purchase verification. We might also use it for the additional reasons listed in
            this policy.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">The following sorts of information may be collected and processed by us depending on
            which of our Services you use:</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Information about how and when you play our games or visit our website(s), what device
            you use to access our games and Services, or information about profile visits; your interactions with us on our
            social media channels are just a few examples of the information we may collect about how you use and interact
            with our games, advertising, and other Services.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">The personal information we get about you when you play our games will remain anonymous
            if you decide not to create a Lost CTRL profile by linking your Facebook account, but we will still gather
            device-level identifiers and data related to those identifiers, such as:</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">identifiers like your IP address, device identifiers, ad identifiers, a Lost
            CTRL-player ID, the country or region you are playing in, and the type of device(s) you use to play our games or
            access our Services, as well as information about how you use our websites and play our games (such as level
            start, level end, session start, booster use, spending, rewards, your progress through the games, session time and
            duration, number of attempts at each level and purchases made).</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">Delivering and Improving Our Services</span></p>
        <p className="c0"><span className="c1">We utilize your personal information to make it possible for us to offer you access to
            our websites, advertising, and other Services and to optimize them all for both you and the rest of our players.
            In order to ensure that our websites function effectively on all platforms and are as pleasurable as possible for
            all of our players, this will include the use and analysis of aggregated data.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">Client Services</span></p>
        <p className="c0"><span className="c1">We utilize your personal information, including information you directly give us in
            communications, to assist you when you need it through our customer service channels. We may also use it to get in
            touch with you, such as when providing customer support or updating you on our services and games. To administer
            your account and connection with us, as well as to enhance your usage of our Services, we will use your
            data.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">Reviews</span></p>
        <p className="c0"><span className="c1">You might occasionally remark on our games and add details, possibly including personal
            information. You freely provide us with all personal information we acquire from your involvement in these
            reviews. We may use such personal data in ways that are compliant with the guidelines set forth in this document
            in order to improve our services, websites, and/or products.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">Research and Analytics</span></p>
        <p className="c0"><span className="c1">To learn more about how you use our services or play our games, we use analytics tools,
            some of which may come from third parties. These tools might make use of cookies or other tracking mechanisms.
            Your information might be used by us for research or surveys.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Prevention of Fraud, Crime, and Cheating</span></p>
        <p className="c0"><span className="c1">To stop fraud, theft, and other crimes, we may use the personal information we have on
            file. Your information could also need to be given to law enforcement officials, fraud prevention organizations,
            and other groups engaged in crime and fraud prevention.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">Uses for Legal Purposes</span></p>
        <p className="c0"><span className="c1">As needed or permitted by any applicable law, we may use your data.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Visiting Our Website</span></p>
        <p className="c0"><span className="c1">When you visit our website, lostctrl.com, (&ldquo;Website&rdquo;), we gather and use
            the personal information listed below about you.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Any information you give us when you contact us using our website.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">2.2 Personal Information Obtained from Outside Sources</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">As was already noted, the majority of the data we gather about you comes from you when
            you use our games, websites, advertisements, or other services. However, we also collect information from third
            parties that you permit to access your personal information (such as when you download our mobile applications
            through the App Store or Google Store or when you link your Facebook account to mobile applications).</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">Facebook Account Connection</span></p>
        <p className="c0"><span className="c1">We will obtain some of your Facebook account information when you check in to your Lost
            CTRL profile using your Facebook login credentials. Your basic Facebook account information will be immediately
            linked to your Lost CTRL profile if you decide to play our games online. We specifically obtain your name,
            Facebook ID, and profile picture. By doing this, you will be able to log in with your account from several devices
            and sync your progress between them. You can utilize other features we make accessible in our games and other
            Services, like asking others to play, as well as view which of your friends are currently using the same game and
            their progress through it (and they can see you and your progress).</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Along with the names and avatars of your Facebook friends who have previously
            downloaded our games, we will also gather the email address linked to your Facebook account. When you first link
            your Facebook account to your Lost CTRL profile, you have the option of denying us access to your email address in
            the Facebook dialog box.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">By logging in with Facebook, you don&rsquo;t allow us to post anything on your page
            without your consent and from learning which of your Facebook friends don&rsquo;t play our game. You can prevent
            this by disabling Facebook while playing our game and changing your app permissions in your Facebook settings if
            you don&rsquo;t want your Facebook friends to be able to see you and your game progress.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">To make sure you completely understand how your Facebook data is shared, we advise you
            to read Facebook&rsquo;s Privacy Policy (https://www.facebook.com/privacy/explanation) and periodically review
            your privacy settings.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">Installing Our Games through the Google Play Store and the App Store</span></p>
        <p className="c0"><span className="c1">The information associated with your credit card or bank account is not accessible to
            Lost CTRL when you install or buy products associated with our mobile apps (such as those you can buy or download
            via the App Store or Google Store). However, the aforementioned third-party payment processors could divulge to us
            non-financial information about your purchases, such as the goods bought to complete your order. The privacy
            options on pertinent third-party applications will determine the personal data that is obtained from the
            third-party businesses.</span></p>
        <p className="c0 c4"><span className="c1"><br/><br/></span></p>
        <ol className="c2 lst-kix_list_3-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Reasons for Processing Your Personal Information</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">We gather and utilize your personal information for the following reasons:</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">to help us deliver our games, make sure your purchases are fulfilled in them, and offer
            you player assistance if you need it; to help us adapt our games for you and your device and to present in-game
            events, deals, and promotions that are appropriate for you;</span></p>
        <p className="c0"><span className="c1">To operate, enhance, and optimize our games and other Services; for the additional
            reasons detailed in this Privacy Policy, such as marketing and advertising;</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">to offer access to specific regions, capabilities, and features of our services; to
            personalize our services, comprehend our users and their preferences to better user experience and satisfaction
            with our services;</span></p>
        <p className="c0"><span className="c1">to give you relevant information, such as confirmations, technical notices, updates,
            security alerts, support and administrative messages; to advise you about in-game updates, new items, or
            promotional offers;</span></p>
        <p className="c0"><span className="c1">to allow players to engage with one another in-game or through our social media
            accounts; to answer your questions and comments; and to offer customer assistance;</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">to measure interest in our Services, to ensure internal quality control, to enable us
            to comply with laws that apply to us, to prevent fraud, to ensure adherence to our terms of service, or when
            necessary to defend, exercise, or establish our legal rights, including those under our Terms of Service
            (https://www.lostctrl.com/terms-conditions/). This includes when it becomes necessary to enforce our Terms of
            Service.</span></p>
        <p className="c0"><span className="c1">to get in touch with you as part of our hiring procedures; as otherwise clearly stated
            in this privacy statement; or as otherwise required by law.</span></p>
        <p className="c0 c4"><span className="c1"><br/><br/></span></p>
        <ol className="c2 lst-kix_list_4-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Marketing</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Based on your interests, we and our third-party partners send you marketing
            communications about our mobile applications and associated Services using the personal information we&rsquo;ve
            gathered about you. For instance, we might display advertisements on third party websites and apps or within our
            own Services.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">We might employ the data we have on you in a variety of ways to advertise Lost
            CTRL&rsquo;s services. This could incorporate:</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">advertising on third-party websites and applications; providing you with personalized
            promotions for our services in the game you play; and providing you with in-game promotions and discounts for the
            services and games of Lost CTRL.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">4.1 What data do we use in marketing?</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">We aim to ensure that you only see marketing from us that might be of interest to you
            by using information about you. Utilizing data that we may already have about you, such as:</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">The games you play and information about how you interact with our games and Services,
            your country or region, and other information that we might obtain from our third-party marketing partners or
            other third parties who have obtained your consent or have another legal right to share that information with us,
            ad identifiers and other non-personal data collected from your device.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">4.2 Marketing Partners</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">We may share information we have gathered about you with such third parties for these
            purposes when we employ various third-party marketing partners to assist us on our behalf when marketing our games
            in media produced by other businesses.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">By combining this information with data they have already gathered about you, our
            marketing partners may be able to assist us in serving adverts to you. When you use their services, as well as
            those of other companies&rsquo; websites and services, they get this information. This information is used by our
            partners to guess what sorts of advertisements you would want to see. While you are using other websites and
            services, they may show you an advertisement for Lost CTRL if they think you would find it interesting to view
            one.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">You can send us an opt-out request by emailing&nbsp;privacy@lostctrl.com&nbsp;if you
            would rather your personal information not be used to provide interest-based advertisements. You should be aware
            that even if you choose not to receive targeted or interest-based advertising, you may still see advertisements
            for Lost CTRL products. However, these ads won&rsquo;t be based on the information you personally provide.</span>
        </p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Your personal information will be used for our marketing partners to collect and use
            information about you to improve advertising, targeting, and measurement systems in accordance with their privacy
            policies if you accept this privacy policy, download or continue playing our games, and unless you opt out of
            interest-based advertising as described in this section.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Your data will not be subject to this Privacy Policy if it is collected by one of our
            marketing partners. For further details, we advise you to consult the privacy policies of our partners.</span></p>
        <p className="c0 c4"><span className="c1"><br/><br/></span></p>
        <p className="c0"><span className="c1">4.3 Third-Party Use of Cookies</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Cookies and other similar technologies may be used by service providers working on our
            behalf to automatically gather data through the Services. Cookies are tiny text files that are installed in
            users&rsquo; web browsers to store preferences. When using our website or services, cookies from these service
            providers may be placed on your device. Service providers will utilize cookies related to the Website for the
            following purposes:</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Operationally Necessary Cookies may be used to maintain the Website&rsquo;s
            functionality.</span></p>
        <p className="c0"><span className="c1">The effectiveness of our website may be evaluated using performance-related cookies,
            including as a part of research into how users interact with our websites.</span></p>
        <p className="c0"><span className="c1">When accessing or using our website, functionality-related cookies may be utilized to
            provide functionality.</span></p>
        <p className="c0"><span className="c1">You can limit the kinds of cookies you allow or have your web browser notify you when
            someone tries to put one on your machine. Flash Cookies function differently than browser cookies, and flash
            cookies might not be erasable with cookie management tools found in web browsers.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">Please be aware that as cookies are required to track and improve your activities, if
            you disable them, you might lose access to some of the features and functionality of our Services.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <ol className="c2 lst-kix_list_5-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Advertising IDs, SDKs and similar technologies</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">To deliver games, websites and interest-based advertising, we use advertising IDs, SDKs
            and similar technologies. This section describes our use of these technologies.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">We use advertising ID and similar technologies to provide our services to you and to
            collect data from your device. These technologies enable us and our third-party advertising partners:</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">To recognize you and your device;</span></p>
        <p className="c0"><span className="c1">provides you with interest-based advertisements;</span></p>
        <p className="c0"><span className="c1">allow our Services to interact with third-party social networks or platforms (if you
            have chosen to allow such interaction);</span></p>
        <p className="c0"><span className="c1">allows us and third parties to provide you with more personalized services, such as
            providing our Services in the appropriate language.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">5.1 How do advertising identifiers and similar technologies work?</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Advertising IDs are non-permanent, non-personal identifiers, such as your Android
            advertising ID and/or Apple advertising ID, that are uniquely associated with your device. Similar technologies
            include pixel tracking in advertising. These technologies allow companies (including marketers and advertisers) to
            recognize your device when you use websites and apps.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">5.2 How to opt out of interest-based advertising on your device and on Facebook, or you
            can reset the advertising ID on your device by changing your device settings. Settings vary from device to device
            but you will usually find the ad identifier settings under &ldquo;privacy&rdquo; or &ldquo;ads&rdquo; in your
            device&rsquo;s settings.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Opting out of Facebook advertising:</span></p>
        <p className="c0"><span className="c1">To update your advertising preferences with Facebook, you will need to log in to your
            account on Facebook and update your settings there.</span></p>
        <p className="c0 c4"><span className="c1"><br/><br/><br/></span></p>
        <ol className="c2 lst-kix_list_6-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Your Rights</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">You have certain rights over your personal data and how we process it. You may exercise
            these rights at any time by contacting us using one of the methods described in the Contact Us section
            below.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">These rights include:</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">Accessing personal data:</span></p>
        <p className="c0"><span className="c1">You have the right to access your personal data that Lost CTRL uses.</span></p>
        <p className="c0"><span className="c1">Edit or delete personal data:</span></p>
        <p className="c0"><span className="c1">You have the right to request that Lost CTRL correct or complete your personal data if
            it is inaccurate or incomplete by sending an email to&nbsp;privacy@lostctrl.com. You may also have the right to
            request that Lost CTRL delete your personal data, where certain conditions apply, by sending an email
            to&nbsp;privacy@lostctrl.com.</span></p>
        <p className="c0"><span className="c1">Deactivate the account:</span></p>
        <p className="c0"><span className="c1">If you want to deactivate your account completely, unlink your Facebook account from
            our mobile games and remove our games from your mobile device. Please note that if you deactivate your account
            completely, all your progress in our games and any unused virtual items will be lost and we will not be able to
            restore them in the future. future.</span></p>
        <p className="c0"><span className="c1">Restrictions on processing personal data:</span></p>
        <p className="c0"><span className="c1">You may have the right to request that Lost CTRL restrict the use of your personal
            data, where certain conditions apply.</span></p>
        <p className="c0"><span className="c1">Objection to the processing of personal data:</span></p>
        <p className="c0"><span className="c1">You may have the right to object to Lost CTRL&rsquo;s use of your personal data, where
            certain conditions apply. Portability of personal data:</span></p>
        <p className="c0"><span className="c1">You may have the right to receive your personal data in a structured and commonly used
            format so that you may use it with another company, where certain conditions apply.</span></p>
        <p className="c0"><span className="c1">Right of withdrawal (California, USA):</span></p>
        <p className="c0"><span className="c1">California residents have the right to opt-out of the sale of personal data, determined
            in a way that may include transferring the data to third parties to personalize advertising for you. If you refuse
            to &ldquo;sell&rdquo; your data, we won&rsquo;t sell your data, including to our advertising partners, but you may
            still see ads in the game and some partners. Our site may serve ads to you, subject to the information they allow
            them to retain.</span></p>
        <p className="c0"><span className="c1">There are other ways to control the personal data we collect about you. For example,
            you can disconnect your Facebook or other social network account from our game or remove our game from your mobile
            device. You can also reset your mobile advertising ID or limit ad tracking entirely using your phone
            settings.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">From time to time, we may send you push notifications through our mobile applications
            with game updates, high scores and other notifications that may be of interest to you. You can opt out of
            receiving these types of communications at any time by changing the settings on your mobile device.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <ol className="c2 lst-kix_list_7-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Children</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">You must be over 13 years old to play the game and use our Services. We do not
            knowingly and knowingly collect personal data from anyone under the age of 13. However, if the Service used by the
            person violates this Privacy Policy, we may collect personal data from that person. If you believe that we may
            have any information from or about a child under the age of 13 (or under the age of 16 in certain jurisdictions of
            European Union countries and/or counties), please contact us.</span></p>
        <p className="c0 c4"><span className="c1"><br/><br/></span></p>
        <ol className="c2 lst-kix_list_8-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Disclosure of your personal data</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">We may share your information as described in this Privacy Policy (to provide the
            Services with our third-party service providers; to comply with legal obligations; to protect and defend our
            rights and property) as part of our services described in this privacy policy. .</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Service providers. We may share your personal data with our service providers. The
            types of service providers (data processors) we work with include companies that provide (i) IT and related
            services; (ii) provide the information and services you request; (iii) customer service activities; (iv) search
            engine and analytics activities that help us improve and optimize the Services; and (v) any support activities
            related to our Services. Lost CTRL has executed appropriate contracts with the service providers that prohibit
            them from using or sharing personal data except as necessary to perform the contracted services on our behalf or
            to comply with applicable legal requirements.</span></p>
        <p className="c0"><span className="c1">Disclosures to Protect Rights of Us, You or Others (e.g., as Required by Law and
            Similar Disclosures). We may access, preserve, and disclose your personal data, other account information and
            content if we believe doing so is required or appropriate:</span></p>
        <p className="c0"><span className="c1">(i) to comply with law enforcement or national security requests and legal process,
            such as a court order or subpoena; (ii) to respond to your requests; (iii) to protect yours, ours or others&rsquo;
            rights, property or safety; (iv) to enforce Lost CTRL&rsquo; policies or contracts; (v) to collect amounts owed to
            Lost CTRL; (vi) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss
            or in connection with an investigation or prosecution of suspected or actual illegal activity; or (vii) if we, in
            good faith, believe that disclosure is otherwise necessary or advisable.</span></p>
        <p className="c0"><span className="c1">In addition, from time to time, server logs may be reviewed for security purposes
            (e.g., to detect unauthorized activity on the Services). In such cases, server log data containing IP addresses
            may be shared with law enforcement bodies, so they may identify users in connection with their investigation of
            the unauthorized activities. Sell &#8203;&#8203;assets and change control. If there is a sale of Lost CTRL assets
            or a corporate reorganization or after a change of control in the share structure of Lost CTRL or in preparation
            for any of these events. Any third party to whom a lost CTRL transfers or sells a lost CTRL asset will have the
            right to continue to use the personal and other information you provide to us in the manner set forth in the
            Rights Policy. this privacy.</span></p>
        <p className="c0 c4"><span className="c1"><br/><br/></span></p>
        <ol className="c2 lst-kix_list_9-0 start">
            <li className="c3 li-bullet-0"><span className="c1">International transfer</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">All personal data collected or stored by Lost CTRL may be stored anywhere in the world,
            including but not limited to the Republic of Turkey, the United States of America, the European Union, the cloud,
            on our servers and on the servers of our service providers. When we transfer your personal data internationally,
            we will take reasonable steps to ensure that it is processed securely, legally and in the manner described herein.
            Please note that laws vary from jurisdiction to jurisdiction, so the privacy laws applicable to places where your
            personal information is transferred or stored, used or otherwise processed may differ. with the privacy laws that
            apply to your place of residence.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">By providing us with your personal data, you consent to the storage of your personal
            data in the aforementioned locations. If you are using our Services from the European Union or other regions with
            laws governing data collection and use, please note that you consent to the transfer of your personal data to
            Turkey. United States and the United States and the processing of your data globally.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">By choosing to visit the Website, use the Services, or provide information to us, you
            agree that any dispute regarding privacy or the terms of this Privacy Policy will be governed by the laws of the
            Republic of Turkey and the arbitration of any dispute arising in connection with Lost CTRL or the Website will be
            subject to the Terms of Use.</span></p>
        <p className="c0 c4"><span className="c1"><br/><br/></span></p>
        <ol className="c2 lst-kix_list_10-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Security</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Personal data you provide to Lost CTRL is stored on servers located in secure
            facilities and uses industry standard security for such data, including firewalls and data encryption for
            assurance purposes. protected by protocols and procedures designed to maintain However, no server, computer,
            communications network or system, or data transmission over the Internet is guaranteed to be 100% secure.
            Therefore, while Lost CTRL strives to protect your personal information, we cannot ensure or warrant the security
            of any personal information you transmit to Lost CTRL or through the Service.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">By using the Service or providing personal information to us, you agree that we may
            communicate with you electronically regarding security, privacy and administrative issues related to your use of
            the Service. You agree to If we learn of a security system&rsquo;s breach, we may attempt to notify you
            electronically by posting a notice on the Services or sending an e-mail to you.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <ol className="c2 lst-kix_list_11-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Legal Bases (for European Economic Area users)</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">If you are an individual in the European Economic Area (EEA), we collect and process
            personal data about you only where we have legal bases for doing so under applicable EU laws. The legal bases
            which we rely upon are the following:</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">Contractual Necessity.This covers data used by us in order to provide you with Services
            that you have requested &ndash; and that we have agreed &ndash; to provide to you, such as our games, customer
            services, chat functionality and third-party social network connectivity;</span></p>
        <p className="c0"><span className="c1">Legal Obligation.This covers data used by us to comply with a legal obligation, such as
            to maintain records relating to tax or to respond to a valid legal request for certain data;</span></p>
        <p className="c0"><span className="c1">Legitimate Interests.This covers data used by us for purposes that are not harmful to
            your privacy and can be reasonably expected within the context of your use of our Services, such as marketing,
            advertising, analytics, research and optimization of our games and other Services; and</span></p>
        <p className="c0"><span className="c1">This is used as a legal basis in the following contexts:</span></p>
        <p className="c0"><span className="c1">the collection of data stored on your device by us and our advertising partners,
            including device information, ad identifiers, IP address, country and region. You consent to this collection when
            you agree to install our game and you can withdraw your consent to this by uninstalling our games.</span></p>
        <p className="c0"><span className="c1">This Privacy Policy explains how you can object to certain types of processing we carry
            out, and where processing is based on your consent, that you can withdraw that consent at any time in the manner
            described in this Privacy Policy. I&rsquo;m explaining. Nevertheless, if you play our games, we collect and
            process some personal information about you, as described separately in this Privacy Policy. If you do not want us
            to collect your information, please stop playing our games and using our services.</span></p>
        <p className="c0 c4"><span className="c1"><br/><br/><br/></span></p>
        <ol className="c2 lst-kix_list_12-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Retaining Information</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">We keep your information for as long as your account is open or as long as it&rsquo;s
            necessary to give you the Service.</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Please take note that even if you request that we delete your personal information, we
            may still need to keep it in order to pursue our legitimate business interests, such as meeting our legal
            requirements, resolving legal disputes, and upholding our agreements.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <ol className="c2 lst-kix_list_13-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Updates to this Privacy Statement</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">Periodically, we might amend our privacy statement. When we do, we&rsquo;ll post the
            revised privacy statement here. Any new policy will go into effect the day after we post it.</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <ol className="c2 lst-kix_list_14-0 start">
            <li className="c3 li-bullet-0"><span className="c1">Get in Touch</span></li>
        </ol>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">To interact with us and/or exercise your rights as described in this privacy statement,
            please get in touch with us at:&nbsp;</span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c0"><span className="c1">privacy@lostctrl.com</span></p>
        <p className="c0 c4"><span className="c1"><br/></span></p>
        <p className="c0"><span className="c1">&nbsp;</span></p>
        <p className="c4 c5"><span className="c1"></span></p>
      
    </Box>
  )
}

export default PrivacyPolicyPage