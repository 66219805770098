import { FC } from "react"
import { Link as LinkRRD } from "react-router-dom"
import { Box, Img, SystemStyleObject, Text, Link } from "@chakra-ui/react"


interface Props{
  className?: string
  sx?: SystemStyleObject
}

const SectionGames:FC<Props> = (props) => {  
  return (
    <Box className={props.className} pos="relative" h="100vh" sx={props.sx} >

      <Box pos="absolute" left="50%" top="50%" transform="translate(-50%,-50%)" w={{sm: "100%", md: "675px", lg: "900px", xl: "1100px"}} aspectRatio="100/45" mx="auto">
        <Box w="79%" h="100%" pos="absolute" left="0%" top="50%" transform="translateY(-50%)" clipPath="polygon(0% 0%,100% 0%,100% 0%,26.5% 100%,0% 100%)">
          
          <Img src="assets/images/sections/games/leftBackground.webp" sx={{position: "absolute", left:"5%", top:"50%", transform:"translateY(-50%)", width: "94%", zIndex:"1"}}/>
        
          <Img src="assets/images/sections/games/leftBackground1.webp" sx={{position: "absolute", left:"5%", top:"50%", transform:"translateY(-50%)", width: "94%", zIndex:"2"}}/>
        
          <Img src="assets/images/sections/games/leftBackground2.webp" sx={{position:"absolute", left:"5%", top:"50%", transform:"translateY(-50%)", width: "45%", zIndex:"3"}}/>
        
          <Img src="assets/images/sections/games/leftItem1.webp" sx={{position: "absolute", left:"67%", top:"4%", transform:"translateY(-2.5%)", width: "10%", zIndex:"4"}}/>
        
          <Img src="assets/images/sections/games/leftCharacter.webp" sx={{position: "absolute", left:"33%", top:"0%", transform:"translateY(-0%)", width: "40%", zIndex:"5"}}/>
        
          <Img src="assets/images/sections/games/leftTextBackground.webp" sx={{position: "absolute", left:"10%", top:"50%", transform:"translateY(-50%)", width: "80%", zIndex:"6"}}/>
        
          <Img src="assets/images/sections/games/leftText.webp" sx={{position: "absolute", left:"10%", top:"50%", transform:"translateY(-50%)", width: "44%", zIndex:"7"}}/>
        
          <Img src="assets/images/sections/games/leftItem2.webp" sx={{position: "absolute", left:"0.5%", top:"16%", transform:"translateY(-16%)", width: "16%", zIndex:"8"}}/>
        
          <Img src="assets/images/sections/games/leftItem3.webp" sx={{position: "absolute", left:"-2%", top:"60%", transform:"translateY(-55%)", width: "17%", zIndex:"8"}}/>
        
          <Img src="assets/images/sections/games/leftItem4.webp" sx={{position: "absolute", left:"2%", top:"87%", transform:"translateY(-71%)", width: "17%", zIndex:"8"}}/>
        
          <Img src="assets/images/sections/games/leftItem5.webp" sx={{position: "absolute", left:"15%", top:"97.5%", transform:"translateY(-81%)", width: "18%", zIndex:"8"}}/>

          <Link pos="absolute" as={LinkRRD} to="/#games" left="5%" top="50%" transform="translateY(-50%)" w="100%" h="80%" zIndex="20" sx={{"& ~ img": {transition: "200ms"}}} _hover={{"& ~ img": {filter: "brightness(1.15)"}}}/>

        </Box>

        <Box w="79%" h="100%" pos="absolute" right="0%" top="50%" transform="translateY(-50%)" clipPath="polygon(73.5% 0%,100% 0%,100% 100%,0% 100%,0% 100%)">

          <Img src="assets/images/sections/games/rightBackground.webp" w="100%" sx={{position: "absolute", right:"5%", top:"50%", transform:"translateY(-50%)", width: "100%", zIndex:"1"}}/>

          <Img src="assets/images/sections/games/rightBackground12.webp" w="100%" sx={{position: "absolute", right:"5%", top:"50%", transform:"translateY(-50%)", width: "100%", zIndex:"2"}}/>

          <Img src="assets/images/sections/games/rightCharacter.webp" w="100%" sx={{position: "absolute", right:"1%", top:"50%", transform:"translateY(-50%)", width: "38%", zIndex:"3"}}/>

          <Img src="assets/images/sections/games/rightText.webp" w="100%" sx={{position: "absolute", right:"25%", top:"50%", transform:"translateY(-50%)", width: "20%", zIndex:"4"}}/>

          <Text fontSize={{sm: "1.5vw", md: "10px", lg: "15px", xl: "17px"}} color="#fff" sx={{position: "absolute", right:"27.5%", top:"65%", transform:"translateY(-61%)", zIndex:"4"}}>To Be Announced</Text>

          <Link pos="absolute" as={LinkRRD} to="/#games" right="5%" top="50%" transform="translateY(-50%)" w="100%" h="80%" zIndex="20" sx={{"& ~ .chakra-image": {transition: "200ms"}, "&:hover ~ .chakra-image": {filter: "brightness(1.15)"}}}/>

        </Box>

        <Box pos="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" zIndex={11} h={{sm: "110%", md: "325px", lg: "450px", xl: "550px"}} aspectRatio="1/1" bgImage="url(assets/images/sections/games/line.webp)" bgRepeat="no-repeat" bgPosition="center" bgSize="100%" pointerEvents="none"/>
      </Box>

    </Box>
  )
}

export default SectionGames