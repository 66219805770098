import { FC, MutableRefObject, useRef, useState, useEffect } from "react"
import { useLocation, useNavigate, Link as LinkRRD } from "react-router-dom"
import { chakra, shouldForwardProp, Box, Img, SystemStyleObject, Text, Link, useMediaQuery } from "@chakra-ui/react"
import {motion, isValidMotionProp, AnimatePresence} from "framer-motion"
import {AiOutlineArrowDown as ArrowDownIcon} from "react-icons/ai"
import {BsMouse2Fill as MouseIcon} from "react-icons/bs"
import {FaFacebookF as IconFacebook, FaTwitter as IconTwitter, FaYoutube as IconYoutube, FaDiscord as IconDiscord, FaInstagram as IconInstagram, FaLinkedin as IconLinkedin} from "react-icons/fa"
import detectDevice from "../../functions/detectDevice"

const socials = [
  {
    href: "https://www.yusufcode.com",
    icon: IconFacebook,
    status: false
  },
  {
    href: "https://www.instagram.com/lostctrlgames/",
    icon: IconInstagram,
    status: true
  },
  {
    href: "https://www.yusufcode.com",
    icon: IconTwitter,
    status: false
  },
  {
    href: "https://www.yusufcode.com",
    icon: IconYoutube,
    status: false
  },
  {
    href: "https://discord.gg/lostctrl",
    icon: IconDiscord,
    status: true
  },
  {
    href: "https://www.linkedin.com/company/lostctrlgames/",
    icon: IconLinkedin,
    status: true
  },
]

interface Props{
  className?: string
  forwardRef?: MutableRefObject<any>
  sx?: SystemStyleObject
}

const SectionIntro:FC<Props> = (props) => {

  const location = useLocation()
  const qrRef = useRef<any>(null)
  const [isBiggerThan768] = useMediaQuery("(min-width: 768px)")
  const detectedDevice = detectDevice()
  const [videoLoaded, setVideoLoaded] = useState(false)
  
  useEffect(()=>{

    let qrCode:any

    if(typeof window !== "undefined"){
      import("qr-code-styling").then(({default: QRCodeStyling})=>{
        
        qrCode = new QRCodeStyling({
          width: (13*window.innerHeight)/100,
          height: (13*window.innerHeight)/100,
          type: "svg",
          data: `${location.pathname}/navigating-to-store`,
          image: "/images/logo/logo2.svg",
          dotsOptions: {
            color: "#000",
            type: "square"
          },
          backgroundOptions: {
            color: "#fff",
          },
          imageOptions: {
            margin: 1
          }
        })
        
        if(qrRef.current?.children.length < 1){
          qrCode.append(qrRef.current)
          // qrCode.download({ name: "qr", extension: "svg" })
        }

      })
    }

    window.addEventListener("resize", function(){

      qrCode.update({
        width: (13*window.innerHeight)/100,
        height: (13*window.innerHeight)/100,
      })
      
    })
  },[qrRef])
  
  return (
    <Box className={props.className} pos="relative" h="100vh" sx={{/* background:"url('./assets/images/sections/intro/1.webp')", backgroundSize:"cover", backgroundRepeat:"no-repeat", */ ...props.sx}} ref={props.forwardRef}>
      <Box pos="absolute" left={{sm: "5px", "2xl": "10px"}} top={{sm: "10px", "2xl": "20px"}} zIndex={10}>
        <Img src="assets/images/logo/logo3.svg" h={{sm: "40px", "2xl": "55px"}} objectFit="cover"/>
      </Box>

      <video poster="./assets/images/sections/intro/1.webp" autoPlay loop muted onLoadedData={()=>setVideoLoaded(true)} style={{objectFit: "cover", width: "100vw", height: "100vh"}}>
        <source src="assets/videos/intro/intro.mp4"></source>
      </video>
    
      <Box pos="absolute" top="0" left="0" w={{sm: "35%", md: "20%"}} h="100%" bg="linear-gradient(to left,transparent,rgba(0,0,0,.75))"/>
      <Box pos="absolute" left="0" bottom="0" w="100%" h="40%" bg="linear-gradient(to bottom,transparent,rgba(0,0,0,.85))"/>

      <Box pos="absolute" bottom={{sm: "75px", md: 0}} left="0" right="0" w="100%" px={{sm: "5px", md: "10px"}} pb={{sm: "10px", "2xl": "20px"}}>
        <Box pos="relative" px={{sm: "0px", md: "14px"}} display="flex">

          <Box borderRight="4px solid #fff" pos="absolute" right="0" bottom="0" h={{sm: "91px", "2xl": "116px"}} display={{sm: "none", md: "block"}}/>
          <Box borderLeft="4px solid #fff" pos="absolute" left="0" bottom="0" h="18px" display={{sm: "none", md: "block"}}/>
          
          <Box display="flex" flexDir="column" justifyContent="left" mt="auto" borderBottom="1px solid #999" w="40%" /* h="2.5vh" */ mr="auto" >
            <Box pos="relative" display="flex" flexDir="row" borderTop="1px solid #999" w="80%" mb="auto"/>

            <Box display="flex" flexDir="row" color="#999" fontSize={{sm: "5px", "2xl": "7px"}} py="3px" whiteSpace="nowrap">
              <Text mr={{sm: "2px", md: "8px"}} bg="#999" color="#555" fontWeight="700" px="1px">LOST</Text>
              <Text mr={{sm: "2px", md: "8px"}}>04/2023</Text>
              <Text mr={{sm: "2px", md: "8px"}} bg="#999" color="#555" fontWeight="700" px="1px">SOFT LAUNCE</Text>
              <Text mr={{sm: "2px", md: "8px"}}>MATCH3</Text>
              <Text mr={{sm: "2px", md: "8px"}} bg="#999" color="#555" fontWeight="700" px="1px">CTRL</Text>
            </Box>
          </Box>
          
          <Box display="flex" flexDir={{sm: "column", md: "row"}} justifyContent="right" alignItems="end" flexWrap="nowrap" borderBottom="1px solid #999" pb="10px" w="40%" ml="auto">
            
            <Box display="flex" flexDir="column" mr={{sm: 0, md: "10px"}} mb={{sm: "5px", md: "0px"}}>
              <Box display="flex" flexDir="row" justifyContent="right" mb={{sm: 0, md: "5px"}}>
                {
                  socials.filter(a=>a.status === true).map((social,i)=>
                    <Link key={i} as={LinkRRD} to={social.href} isExternal padding="7px" mr={{sm: 0, md: "5px"}} transition="0.1s" sx={{"&:last-child": {pr: 0, mr: 0},"& path": {transition:"0.1s"}}} _hover={{"& path": {color: "#fff"}}}>
                      <Box as={social.icon} fontSize={{sm: "20px", "2xl": "25px"}} color="#ddd"/>
                    </Link>
                  )
                }
              </Box>
              <Box display="flex" flexDir={{sm: "column", md: "row"}}>
                <Img src={"assets/images/store/play-store.png"} w={{sm: "125px", md: "90px", lg: "100px", "2xl": "130px"}} mr={{sm: 0, md: "10px"}} mb={{sm: "5px", md: "0"}} cursor="pointer" onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.LostCTRL.NorasArk.match.quest", "_blank")}/>
                <Img src={"assets/images/store/app-store.png"} w={{sm: "125px", md: "90px", lg: "100px", "2xl": "130px"}} cursor="pointer" onClick={()=>window.open("https://apps.apple.com/tr/app/noras-ark-match-3-puzzle/id1621714900", "_blank")}/>
              </Box>
            </Box>
            
            <Box display="flex" flexDir="column" textAlign="center" w={{sm: "125px", md: "85px", lg: "105px"}}>
              <Text textTransform="uppercase" fontSize={{sm: "8px", md: "10px"}} fontWeight="200" mb={{sm: "2.5px", md: "5px"}} color="#fff">Scan to download</Text>
              <Box pos="relative" w={{sm: "123px", md: "85px", lg: "105px"}} h={{sm: "123px", md: "85px", lg: "105px"}}>
                <Img src="assets/images/qr/qr2.svg" w="100%" h="100%"/>
                {/* <Box ref={qrRef} w="100%" h="100%"/> */}
                <ChakraBox height="2px" w="100%" bg="#fff" borderRadius="50%" pos="absolute" left="0" boxShadow="inset 0 0 1px 0px rgba(244, 189, 25,1), 0 0 10px 3px rgba(244, 189, 25,1)"
                  initial={{top: 6}} animate={{top: "95%"}} transition={{duration:"1.4", repeat: "Infinity", repeatType: "reverse", ease: "linear"}}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {
        isBiggerThan768 ?
          <motion.div style={{position: "absolute", left: "50%", display: "flex", flexDirection: "column", alignItems: "center", transform: "translateX(-50%)", color: "#fff"}}
            animate={{bottom: ["30px", "10px"], opacity: [0,1,0]}}
            transition={{repeat: Infinity, duration: "1.5", type: "linear"}}
          >
            <MouseIcon fontSize="17.5px" style={{marginBottom: "5px"}}/>
            <ArrowDownIcon fontSize="12px"/>
            <Text style={{fontSize:"7px"}}>Scroll Down</Text>
          </motion.div>
          :
          <motion.div style={{position: "absolute", left: "50%", display: "flex", flexDirection: "column", alignItems: "center", transform: "translateX(-50%)", color: "#fff"}}
            animate={{bottom: ["110px", "90px"], opacity: [0,1,0]}}
            transition={{repeat: Infinity, duration: "1.5", type: "linear"}}
          >
            <MouseIcon fontSize="17.5px" style={{marginBottom: "5px"}}/>
            <ArrowDownIcon fontSize="12px"/>
            <Text style={{fontSize:"7px"}}>Scroll Down</Text>
          </motion.div>
      }

    </Box>
  )
}

export default SectionIntro

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})