import { extendTheme} from "@chakra-ui/react"

export default extendTheme({
  breakpoints: {
    "sm": 300,
    "md": 768,
    "lg": 991,
    "xl": 1200,
    "2xl": 1366,
  }
})