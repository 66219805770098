import { FC } from "react"
import { Box, Img, SystemStyleObject, Text } from "@chakra-ui/react"

interface Props{
  className?: string
  sx?: SystemStyleObject
}

const SectionAboutUs:FC<Props> = (props) => {  

  const aboutUsText = `With years of experience in game development and creative marketing, we created lostctrl in April 2021. We had a burning passion to create awesome games, and this simple yet profound passion has fueled a wealth of ideas, inspirations, and stories.`

  const aboutUsText2 = `Our excitement quickly turned into a mission to unlock the joy of gaming for everyone. A mission this big can’t be completed without a strong, skillful, and perfectly-diverse squad. No matter the fictional borders or limitations, we invited driven, passionate, and creative talent to our team worldwide. 
  At the heart of our games lies the pursuit of the perfect gaming experience: An awesome game must fill players with excitement and turns them into heroes of their own adventures. It’s called “feelgood play”, and it’s been our main focus since Day 1 of our studio.`
  
  const aboutUsText3 = `With this vision in mind, we crafted two Match 3 games that carry incredible IP potential. “Uniqueness” is our North Star through game mechanics, narrative, and art. We’re here to level up the game industry, introducing a new wave of next-gen games with never-before-seen mechanics. 
  Join us on this thrilling journey because we want to amaze you with our games! Connect with us on social media or drop us a message. We can’t wait to share the joyous wonder of gaming with you.`
  
  return (
    <Box className={props.className} pos="relative" h="100vh" background="url('assets/images/sections/aboutUs/bg.webp')" bgSize="cover" bgPosition="left" bgRepeat="no-repeat" sx={props.sx}>
      
      <Box pos="absolute" top="50%" left="50%" transform="translate(-50%,-50%)" w={{sm: "80%", md: "625px", lg: "825px", xl: "900px"}} display="flex" alignItems={{sm: "center", lg: "end"}}>
        <Img src="assets/images/sections/aboutUs/1.webp" w={{sm: "150px", md: "200px", lg: "250px"}} h={{sm: "150px", md: "200px", lg: "250px"}} display={{sm: "none", md: "block"}}/>
        <Box ml={{sm: "0", md: "40px"}} display="flex" flexDir="column" color="#fff">
          <Img src="assets/images/logo/logo3.svg" w={{sm: "200px", md: "240px", lg: "280px"}} mx="auto" mb={{sm: "20px", md: "30px"}}/>
          <Text fontSize={{sm: "8px", md: "11px"}} mb="20px" color="#fff">{aboutUsText}</Text>
          <Text fontSize={{sm: "8px", md: "11px"}} mb="20px" color="#fff">{aboutUsText2}</Text>
          <Text fontSize={{sm: "8px", md: "11px"}} color="#fff">{aboutUsText3}</Text>
        </Box>
      </Box>
      
    </Box>
  )
}

export default SectionAboutUs