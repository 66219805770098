import React, { useEffect, useRef, useState } from 'react'
import { Link as LinkRRD, useParams, useNavigate } from 'react-router-dom'

import { Box, Img, Link, Text } from '@chakra-ui/react'
import blogsData from '../assets/data/blogs'
import InterfaceBlog from "../interfaces/interfaceBlog"
import { Helmet } from 'react-helmet-async'
import Footer from '../components/sections/footer'

const BlogPage = () => {

  const navigate = useNavigate()
  const {blogName} = useParams()
  const [foundedBlog, setFoundedBlog] = useState<InterfaceBlog>()
  
  useEffect(()=>{
    
    const localFoundedBlog = blogsData.find(blog=>blog.url === blogName)

    if(localFoundedBlog){
      setFoundedBlog(localFoundedBlog)
    }else {
      navigate("/blogs")
    }
    
  },[blogName])

  
  return (
    <Box p={{sm: "5px 10px", md: "25px 50px"}} overflow="auto" h="100vh"
      sx={{
        "&": {
          background:"url('/assets/images/sections/bg.webp')", backgroundSize:"cover", backgroundRepeat:"no-repeat"
        }
      }}
    >
      <Helmet>
        <title>Lost CTRL | {String(foundedBlog?.title)}</title>
        <meta name="description" content={foundedBlog?.description}></meta>
        <meta name="keywords" content={foundedBlog?.keywords}/>
        <meta name="author" content={foundedBlog?.author}/>
      </Helmet>



      <Box pos="relative" w={{sm: "95%", md: "100%"}} h="40px" mx="auto" mt={{sm: "10px", md: "20px"}} mb={{sm: "40px", md: "75px"}}>
        {
          foundedBlog && <Link as={LinkRRD} to="/blogs" pos="absolute" left="0" top="50%" transform="translate(0, -50%)" color="#fff" fontSize={{sm: "15px", md: "25px"}} w="fit-content">Blogs</Link>
        }
        
        <Link as={LinkRRD} to="/" pos="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" w="fit-content">
          <Img src="/assets/images/logo/logo3.svg" h={{sm: "40px", "2xl": "55px"}}/>
        </Link>
      </Box>
      
      <Box
        sx={{
          mx: "auto",
          w: {sm: "95%", md: "100%"},
          bg: "#00000055",
          p: "25px",
          mb: "25px"
        }}
      >
        <Box
          sx={{
            "& h1": {
              fontSize: {sm: "20px", md: "40px"},
              color: "#fff",
              mb: "20px"
            },
            "& p": {
              fontSize: {sm: "12px", md: "15px"},
              color: "#fff",
              mb: "15px"
            },
            "& img": {
              w: {sm: "100%", md: "500px"},
              mx: "auto",
              my: "50px"
            },
            "& ul": {
              fontSize: {sm: "12px", md: "15px"},
              color: "#fff",
              mb: "15px",
              listStyle: "none",
              "& li::before": {
                content: "'-'"
              }
            },
            "& a:hover": {
              textDecoration: "underline"
            },
          }}
          dangerouslySetInnerHTML={{__html: foundedBlog?.content ? foundedBlog?.content : "" }}
        />
      </Box>

      <Footer sx={{h: "fit-content", mb: {sm: "85px", md: "initial"}}}/>
      
    </Box>
  )
}

export default BlogPage