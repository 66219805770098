import React, { useEffect, useRef, useState } from 'react'
import { Link as LinkRRD, useParams } from 'react-router-dom'
import SliderBlogs from '../components/sliderBlogs'

import { Box, Img, Link, Text } from '@chakra-ui/react'
import blogsData from '../assets/data/blogs'
import InterfaceBlog from "../interfaces/interfaceBlog"
import { Helmet } from 'react-helmet-async'
import Footer from '../components/sections/footer'

const BlogsPage = () => {
  return (
    <Box p={{sm: "5px 10px", md: "25px 50px"}} overflow="auto" h="100vh"
      sx={{
        "&": {
          background:"url('/assets/images/sections/bg.webp')", backgroundSize:"cover", backgroundRepeat:"no-repeat"
        }
      }}
    >
      <Helmet>
        <title>Lost CTRL | Blogs</title>
        <meta name="description" content="We are sharing our news and conents about our games here."></meta>
        <meta name="keywords" content="lostctrl, blogs, contents, games, projects, news"/>
      </Helmet>

      <Box pos="relative" w={{sm: "95%", md: "100%"}} h="40px" mx="auto" mt={{sm: "10px", md: "20px"}} mb={{sm: "40px", md: "75px"}}>
        <Link as={LinkRRD} to="/" pos="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" w="fit-content">
          <Img src="/assets/images/logo/logo3.svg" h={{sm: "40px", "2xl": "55px"}}/>
        </Link>
      </Box>
      
      <Box
        sx={{
          mx: "auto",
          w: {sm: "95%", md: "100%"},
          bg: "transparent",
          p: "0",
          mb: "25px"
        }}
      >
        <SliderBlogs titles={["Latest", "Tales from Ark"]} datas={[blogsData]} bigger/>
      </Box>

      <Footer sx={{h: "fit-content", mb: {sm: "85px", md: "initial"}}}/>
      
    </Box>
  )
}

export default BlogsPage