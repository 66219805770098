import React, { FC, useState } from 'react'
import { Link as LinkRRD } from 'react-router-dom'
import { Box, Img, Link, Text } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import "swiper/css"
import "swiper/css/pagination"
import logoHead from "../assets/images/logo/logoHead.js"

const textAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {duration: 0.3, delay: 0.1}
  },
  exit: {
    opacity: 0,
    transition: {duration: 0.3}
  }
}

interface Props {
  datas: {href: string, src: string}[]
}

const SliderImages:FC<Props> = (props) => {

  const [sliderIndex, setSliderIndex] = useState(1)
  
  return (
    <Box
      sx={{
        "&": {
          pos: "relative",
          w: "100%",
          display:"flex",
          flexDir:"column",
          justifyContent:"center",
        },
        "& > .newsSwiper": {
          pos: "initial",
          w: {sm: "85vw", md: "300px", lg: "400px", xl: "450px", "2xl": "500px"},
          // h: "280px",
          aspectRatio: "25/14",
          "& > .swiper-wrapper": {
            "& > .swiper-slide": {
              h: "100%",
              w: "100%",
              "& > a > img": {
                h: "100%",
                w: "100%",
                objectFit: "cover"
              }
            }
          },
        }
      }}
    >
      <Box fontSize={{sm: "12px", md: "14px", "2xl": "18px"}} fontWeight="300" color="#fff" letterSpacing="2px" lineHeight="1" mb={{sm: "5px", md: "10px"}} display="flex" pos="relative">
        <AnimatePresence>
          <Text color="#f8bf13" pos="absolute" left="0" top="0"
            as={motion.p} key={sliderIndex} variants={textAnimation} initial="initial" animate="animate" exit="exit"
          >{sliderIndex > 0 && sliderIndex < 10 && "0"}{sliderIndex}</Text>
        </AnimatePresence>
        <Text ml={{sm: "19px", md: "23px", "2xl": "28px"}}>/</Text>
        <Text>{props.datas.length > 0 && props.datas.length < 10 && "0"}{props.datas.length}</Text>
        <Text>{"//"}</Text>
      </Box>
      <Swiper
        pagination={{
          el: ".news-swiper-pagination",
          clickable: true,
          renderBullet: function (index, className) {
            return `<div class="${className}">${logoHead}</div>`
          }
        }}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        loop
        speed={400}
        onSlideChange={(swiper)=>setSliderIndex(swiper.realIndex + 1)}
        className="newsSwiper"
      >
        {
          props.datas.map((sliderItem,i)=>
            <SwiperSlide key={i}>
              <Link as={LinkRRD} to={sliderItem.href}>
                <Img src={sliderItem.src}/>
              </Link>
            </SwiperSlide>
          )
        }
      </Swiper>
      <Box className="news-swiper-pagination"
        sx={{
          "&":{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            mt: {sm: "5px", md: "10px", "2xl": "20px"},
            "&::before": {
              content: "''",
              marginRight: "auto",
              bgColor: "#999",
              h: "1px",
              w: "-webkit-fill-available",
              display: "inline-block"
            },
            "& > .swiper-pagination-bullet": {
              pos: "relative",
              opacity: "1",
              bgColor: "transparent",
              w: {sm: "12px", md: "14px"},
              h: {sm: "12px", md: "14px"},
              cursor: "pointer",
              aspectRatio: "1",
              ml: "18px!important",
              mr: "0!important",
              border: "1px solid transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
              "&:first-of-type": {
                ml: "27px!important"
              },
              "& > svg": {
                w: {sm: "2px", md: "3px"},
                h: {sm: "2px", md: "3px"},
                transition: "200ms",
                "& .head": {
                  fill: "#fff"
                },
                "& .eyes": {
                  fill: "transparent"
                }
              },
              "&.swiper-pagination-bullet-active":{
                border: "1px solid #f8bf13",
                transition: "600ms",
                "& > svg": {
                  w: {sm: "8px", md: "10px"},
                  h: {sm: "8px", md: "10px"},
                  "& .head": {
                    fill: "#f8bf13"
                  },
                  "& .eyes": {
                    fill: "#000"
                  },
                }
              },
            }
          }
        }}
      />
    </Box>
  )
}

export default SliderImages