import { FC, useState } from "react"
import { Link as LinkRRD } from "react-router-dom"
import { Box, Img, Link, SystemStyleObject, Text, useMediaQuery } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, EffectCoverflow } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import logoHead from "../../assets/images/logo/logoHead.js"
import Atropos from "atropos/react"
import "atropos/css"

interface Props{
  className?: string
  sx?: SystemStyleObject
}

const SectionGameplay:FC<Props> = (props) => {  

  const [firstSliderCurrentIndex, setFirstSliderCurrentIndex] = useState(1)
  const [isBiggerThan768] = useMediaQuery("(min-width: 768px)")

  const [sliderItems, setSliderItems] = useState([
    {
      url: "gameplay/1",
      src: "assets/images/sections/gameplay/1.webp"
    },
    {
      url: "gameplay/2",
      src: "assets/images/sections/gameplay/2.webp"
    },
    {
      url: "gameplay/3",
      src: "assets/images/sections/gameplay/3.webp"
    },
    {
      url: "gameplay/4",
      src: "assets/images/sections/gameplay/4.webp"
    },
  ])
  
  return (
    <Box className={props.className} pos="relative" h="100vh" sx={props.sx}>
      
      <Box pos="absolute" top="50%" left="50%" transform="translate(-50%,-50%)">

        {
          isBiggerThan768 ?
            <Atropos
              rotateXMax={10}
              rotateYMax={10}
              activeOffset={0}
              commonOrigin={false}
              alwaysActive
              duration={500}
              highlight={false}
              shadow={false}
              style={{
                width: "100vw",
                height: "100vh"
              }}
            >
              <Box w="fit-content" h="fit-content" display="flex" flexDir="column" justifyContent="center"
                sx={{
                  "&": {
                    pos: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    zIndex: "2"
                  },
                  "& > .gameplaySwiper": {
                    w: {sm: "100vw", md: "90vw", lg: "885px"},
                    aspectRatio: "20/13",
                    display: "flex",
                    alignItems: "center",
                    "& > .swiper-wrapper": {
                      alignItems: "center",
                      h: "fit-content",
                      "& > .swiper-slide": {
                        borderRadius: {sm: "15px", md: "30px"},
                        overflow: "hidden",
                      },
                      "& > .swiper-slide-active": {
                        
                      },
                    },
                    "& > .swiper-button-next": {
                      right: "-40px"
                    },
                    "& > .swiper-button-prev": {
                      left: "-40px"
                    }
                  }
                }}
              >
                <Swiper
                  data-atropos-offset="0"
                  pagination={{
                    el: ".gameplay-swiper-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                      return `<div class="${className}">${logoHead}</div>`
                    }
                  }}
                  navigation
                  modules={[Pagination, Navigation, EffectCoverflow]}
                  speed={400}
                  slidesPerView={3}
                  centeredSlides={true}
                  initialSlide={1}
                  effect="coverflow"
                  onSlideChange={(swiper)=>setFirstSliderCurrentIndex(swiper.realIndex + 1)}
                  className="gameplaySwiper"
                  breakpoints={{
                    300: {
                      spaceBetween: 10
                    },
                    768: {
                      spaceBetween: 25
                    },
                    991: {
                      spaceBetween: 50
                    }
                  }}
                >
                  {
                    sliderItems.map((sliderItem,i)=>
                      <SwiperSlide key={i}>
                        <Link as={LinkRRD} to={sliderItem.src} isExternal>
                          <Img src={sliderItem.src}/>
                        </Link>
                      </SwiperSlide>
                    )
                  }
                </Swiper>
                <Box className="gameplay-swiper-pagination" data-atropos-offset="2"
                  sx={{
                    "&":{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "& .swiper-pagination-bullet": {
                        pos: "relative",
                        opacity: "1",
                        bgColor: "transparent",
                        w: {sm: "12px", md: "14px"},
                        h: {sm: "12px", md: "14px"},
                        cursor: "pointer",
                        aspectRatio: "1",
                        mx: "0.65vw!important",
                        border: "1px solid transparent",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "100%",
                        "&:first-of-type": {
                          ml: "0!important",
                        },
                        "& > svg": {
                          w: {sm: "2px", md: "3px"},
                          h: {sm: "2px", md: "3px"},
                          transition: "200ms",
                          "& .head": {
                            fill: "#fff"
                          },
                          "& .eyes": {
                            fill: "transparent"
                          }
                        },
                        "&.swiper-pagination-bullet-active":{
                          border: "1px solid #f8bf13",
                          transition: "600ms",
                          "& > svg": {
                            w: {sm: "8px", md: "10px"},
                            h: {sm: "8px", md: "10px"},
                            "& .head": {
                              fill: "#f8bf13"
                            },
                            "& .eyes": {
                              fill: "#000"
                            },
                          }
                        },
                      }
                    },
                  }}
                />
                <Box w={{sm: "95vw", md: "85vw", lg: "825px"}} h="90%" pos="absolute" left="50%" top="50%" transform="translate(-50%,-50%)">
                  <Img src="assets/images/sections/gameplay/plus.webp" w="10vh" pos="absolute" right="0vh" top="0vh" data-atropos-offset="-25" zIndex={1}/>
                  <Img src="assets/images/sections/gameplay/plus.webp" w="10vh" pos="absolute" left="0vh" bottom="0vh" data-atropos-offset="-25" zIndex={3}/>
                </Box>
              </Box>
            </Atropos>
            :
            <Box w="fit-content" h="fit-content" display="flex" flexDir="column" justifyContent="center"
              sx={{
                "&": {
                  pos: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "2"
                },
                "& > .gameplaySwiper": {
                  w: {sm: "100vw", md: "90vw", lg: "885px"},
                  aspectRatio: "20/13",
                  display: "flex",
                  alignItems: "center",
                  "& > .swiper-wrapper": {
                    alignItems: "center",
                    h: "fit-content",
                    "& > .swiper-slide": {
                      borderRadius: {sm: "15px", md: "30px"},
                      overflow: "hidden",
                    },
                    "& > .swiper-slide-active": {
                      
                    },
                  },
                  "& > .swiper-button-next": {
                    right: "-40px"
                  },
                  "& > .swiper-button-prev": {
                    left: "-40px"
                  }
                }
              }}
            >
              <Swiper
                data-atropos-offset="0"
                pagination={{
                  el: ".gameplay-swiper-pagination",
                  clickable: true,
                  renderBullet: function (index, className) {
                    return `<div class="${className}">${logoHead}</div>`
                  }
                }}
                navigation
                modules={[Pagination, Navigation, EffectCoverflow]}
                speed={400}
                slidesPerView={3}
                centeredSlides={true}
                initialSlide={1}
                effect="coverflow"
                onSlideChange={(swiper)=>setFirstSliderCurrentIndex(swiper.realIndex + 1)}
                className="gameplaySwiper"
                breakpoints={{
                  300: {
                    spaceBetween: 10
                  },
                  768: {
                    spaceBetween: 25
                  },
                  991: {
                    spaceBetween: 50
                  }
                }}
              >
                {
                  sliderItems.map((sliderItem,i)=>
                    <SwiperSlide key={i}>
                      <Link as={LinkRRD} to={sliderItem.src} isExternal>
                        <Img src={sliderItem.src}/>
                      </Link>
                    </SwiperSlide>
                  )
                }
              </Swiper>
              <Box className="gameplay-swiper-pagination" data-atropos-offset="2"
                sx={{
                  "&":{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& .swiper-pagination-bullet": {
                      pos: "relative",
                      opacity: "1",
                      bgColor: "transparent",
                      w: {sm: "12px", md: "14px"},
                      h: {sm: "12px", md: "14px"},
                      cursor: "pointer",
                      aspectRatio: "1",
                      mx: "0.65vw!important",
                      border: "1px solid transparent",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "100%",
                      "&:first-of-type": {
                        ml: "0!important",
                      },
                      "& > svg": {
                        w: {sm: "2px", md: "3px"},
                        h: {sm: "2px", md: "3px"},
                        transition: "200ms",
                        "& .head": {
                          fill: "#fff"
                        },
                        "& .eyes": {
                          fill: "transparent"
                        }
                      },
                      "&.swiper-pagination-bullet-active":{
                        border: "1px solid #f8bf13",
                        transition: "600ms",
                        "& > svg": {
                          w: {sm: "8px", md: "10px"},
                          h: {sm: "8px", md: "10px"},
                          "& .head": {
                            fill: "#f8bf13"
                          },
                          "& .eyes": {
                            fill: "#000"
                          },
                        }
                      },
                    }
                  },
                }}
              />
              <Box w={{sm: "95vw", md: "85vw", lg: "825px"}} h="90%" pos="absolute" left="50%" top="50%" transform="translate(-50%,-50%)">
                <Img src="assets/images/sections/gameplay/plus.webp" w="10vh" pos="absolute" right="0vh" top="0vh" data-atropos-offset="-25" zIndex={1}/>
                <Img src="assets/images/sections/gameplay/plus.webp" w="10vh" pos="absolute" left="0vh" bottom="0vh" data-atropos-offset="-25" zIndex={3}/>
              </Box>
            </Box>
        }

      </Box>
      
    </Box>
  )
}

export default SectionGameplay