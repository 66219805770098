import { FC } from "react"
import { Link as LinkRRD } from "react-router-dom"
import { Box, Button, Img, Input, Link, SystemStyleObject, Text, Textarea } from "@chakra-ui/react"
import logoHead from "../../assets/images/logo/logoHead2.js"
import {FaFacebookF as IconFacebook, FaTwitter as IconTwitter, FaYoutube as IconYoutube, FaDiscord as IconDiscord, FaInstagram as IconInstagram, FaLinkedin as IconLinkedin} from "react-icons/fa"

const socials = [
  {
    href: "https://www.yusufcode.com",
    icon: IconFacebook,
    status: false
  },
  {
    href: "https://www.instagram.com/lostctrlgames/",
    icon: IconInstagram,
    status: true
  },
  {
    href: "https://www.yusufcode.com",
    icon: IconTwitter,
    status: false
  },
  {
    href: "https://www.yusufcode.com",
    icon: IconYoutube,
    status: false
  },
  {
    href: "https://discord.gg/lostctrl",
    icon: IconDiscord,
    status: true
  },
  {
    href: "https://www.linkedin.com/company/lostctrlgames/",
    icon: IconLinkedin,
    status: true
  },
]

interface Props{
  className?: string
  sx?: SystemStyleObject
}

const SectionContactUs:FC<Props> = (props) => {
  
  return (
    <Box className={props.className} pos="relative" h="100vh" sx={props.sx}>
      
      <Box pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" w={{sm: "80%", md: "600px", lg: "800px"}} display="flex" flexDir={{sm: "column", md: "row"}} bg="#66666611" backdropFilter="blur(2px)" borderRadius={{sm: "7.5px", md: "20px"}}>
        
        <Box w={{sm: "100%", md: "50%"}} h="100%" p={{sm: "10px", md: "20px"}} pr={{md: "10px"}}>

          <Box mb="10px">
            <Text color="#fff" fontSize={{sm: "8px", md: "12px"}}>Full Name</Text>
            <Input color="#fff" type="text" fontSize={{sm: "10px", md: "13px"}} p="10px" h={{sm: "30px", md: "40px"}}/>
          </Box>

          <Box mb="10px">
            <Text color="#fff" fontSize={{sm: "8px", md: "12px"}}>Email</Text>
            <Input color="#fff" type="email" fontSize={{sm: "10px", md: "13px"}} p="10px" h={{sm: "30px", md: "40px"}}/>
          </Box>

          <Box mb="10px">
            <Text color="#fff" fontSize={{sm: "8px", md: "12px"}}>Message</Text>
            <Textarea color="#fff" h={{sm: "80px", md: "200px"}} minH={{sm: "80px", md: "200px"}} maxH={{sm: "80px", md: "200px"}}/>
          </Box>

          <Button w="100%" fontSize="10px" h="30px" color="#000" bg="#ddd" _hover={{bg: "#fff"}} _active={{transform: "scale(0.9)"}}>Send</Button>
          
        </Box>

        <Box w={{sm: "100%", md: "50%"}} h="100%" p={{sm: "10px", md: "20px"}} pl={{md: "10px"}} pt={{sm: 0, md: "38px!important"}} display="flex" flexDir="column">

          <Box pos="relative" w="100%" h={{sm: "150px", md: "334px"}} mb="10px" /* borderRadius="100%" */ overflow="hidden" borderRadius="15px">
            <Box
              sx={{
                "&": {
                  pos: "absolute",
                  w: "100%",
                  h: "100%",
                  pointerEvents: "none",
                  "& svg": {
                    w: "100%",
                    h: "100%",
                    "& .head": {
                      fill: "transparent"
                    },
                    "& .eyes": {
                      fill: "#f8bf13",
                      opacity: 0.8
                    }
                  }
                },
              }}
            >
              {logoHead}
            </Box>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.099394120842!2d29.016945997355762!3d41.07492413177748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab66a90fa1725%3A0xe8af6d771072c48c!2zRXRpbGVyLCBCVVJDIEFQVCwgQXl0YXIgQ2QuIE5vOjM2IEQ6MTEsIDM0MzQwIEJlxZ9pa3RhxZ8vxLBzdGFuYnVs!5e0!3m2!1sen!2str!4v1689781109251!5m2!1sen!2str" width="100%" height="100%" loading="lazy"></iframe>
          </Box>

          <Box display="flex" flexDir="row" justifyContent="center" alignItems="center" h="30px">
            {
              socials.filter(a=>a.status === true).map((social,i)=>
                <Link key={i} as={LinkRRD} to={social.href} isExternal h="fit-content" padding="0 7px" mr={{sm: 0, md: "5px"}} transition="0s" sx={{"&:last-child": {pr: 0, mr: 0},"& path": {color: "#ddd", transition:"0.1s"}}} _hover={{"& path": {color: "#fff"}}}>
                  <Box as={social.icon} fontSize="25px"/>
                </Link>
              )
            }
          </Box>

        </Box>
        
      </Box>
      
    </Box>
  )
}

export default SectionContactUs