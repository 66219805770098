import { FC, useState } from "react"
import { Box, Img, SystemStyleObject, Text } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import logoHead from "../../assets/images/logo/logoHead.js"

const texts = {
  novus: "I am Novus, the cosmic force that ignited the very beginning of it all. It fills me with immense joy to see how far my energy has traveled, shaping worlds and giving birth to life across the cosmos. Among the countless creations born from my explosive dance, there is one soul who holds a special place in my heart – Nora. A bright and curious hero destined for greatness. As she journey through this magical world, I will watch her with excitement. I and her, we noticed a sorrowful energy on one of the planets – Earth. Innocent animals, vulnerable and enchanting, are facing the wrath of an enigmatic energy. My cosmic heart aches, witnessing the animals’ suffering. I trust in Nora. As you venture with us, remember that you are not alone; Nora and I stand with you!",
  nora: "Hey! I’m Nora. I’m beyond excited to finally meet you! You know, life has a funny way of surprising you, and I can relate to that because I was born from the magnificient explosion of a supernova. Can you believe it? A star’s breathtaking light gave birth to me, and that’s just the beginning of my extraordinary journey. In this magical world I call home, there’s a lot to discover. I’m always up for a thrilling adventure. I might be young but bravery and curiosity run through my veins. I want to explore this world and others, protect them and build new worlds. But, let me tell you a little secret: It won’t be easy. There will be lots of challenges, blockers, and enemies. Let’s make this world a better place, one Match at a time! What do you say? Will you join me?",
  ark: "Hellooo! I am Ark. They say I’m pure energy. I’m entrusted with a unique purpose by the mighthy Novus. I’m here to help Nora in her thrilling adventures. When Nora first came to this magical world, I was drawn to her like stardust to a black hole. She was a burst of joy and courage, and I knew right then that I wanted to be a part of her incredible journey. Novus gave us a bound. I became Nora’s loyal friend, ready to provide her with all the support and power she would ever need. I’m not one to brag but I can shift into anything – a rocket when she needs a powerful beam, or a source of blast. Helping her, I’m thrilling with joy and wonder. I may be playful, but I’m a problem solver!",
  frank: "You know what? I’m not as talkative as my companions Nora or Ark. Wanna catch up with me in my cat-licious adventures? See you in game, then. ",
  animals: "We are a great squad of animals, living in our peaceful world, hidden away from the prying eyes of the unknown. Until one strange day, everything changed when we stumbled upon Nora. Now, don't get us wrong, she seems friendly enough, but we're a bit suspicious. You see, we've lived in our cozy little community for as long as we can remember, and we've never encountered anyone from the outside world. That is, until that mysterious figure appeared, looking exactly like Nora. We couldn't shake off the eerie feeling it brought upon us. But fate had other plans in store for us. One day, we found ourselves face to face with Nora and Ark. They were unexpected visitors to our tranquil domain, and truth be told, we don’t know what to make of them. Is she really here to save us and build a better new world? Join us, let’s figure it out together.",
}

interface Props{
  className?: string
  sx?: SystemStyleObject
}

const SectionHeroes:FC<Props> = (props) => {

  const [swiperActiveIndex, setSwiperActiveIndex] = useState(1)
  
  return (
    <Box className={props.className} h="100vh" pos="relative" background="url('/assets/images/sections/heroes/bg.webp')" bgSize="cover" bgPosition="left" bgRepeat="no-repeat" sx={props.sx}>
      <Box
        sx={{
          "&": {
            pos: "relative"
          },
          "& > .heroesSwiper": {
            
            "& > .swiper-wrapper": {
              
              "& > .swiper-slide": {
                w: "100vw",
                h: "100vh",
                "& > img": {
                  
                }
              }
            },
          }
        }}
      >
        <Swiper
          speed={800}
          loop
          pagination={{
            clickable: true,
            el: ".heroes-swiper-pagination",
            renderBullet: function (i, className) {
              return `<div class="${className}">${String(logoHead)}</div>`
            },
          }}
          modules={[Pagination]}
          onSlideChange={(swiper)=>setSwiperActiveIndex(swiper.realIndex + 1)}
          className="heroesSwiper"
        >

          <SwiperSlide style={{display: "flex", alignItems: "center", overflow: "hidden"}}>

            <Box sx={{pos: "relative", w: {sm: "100%", md: "550px", xl: "650px", "2xl": "750px"}, aspectRatio: "1/1", mx: "auto"}}>

              <Img src="/assets/images/sections/heroes/nora1.webp" sx={{position: "absolute", left:{sm: "35%", md: "15%"}, top:{sm: "0%", md: "50%"}, transform:{sm: "translate(-35%,-0%)", md: "translate(-15%,-50%)"}, width: "55%", zIndex:"2"}}/>

              <Img src="/assets/images/sections/heroes/nora2.webp" sx={{position: "absolute", left:{sm: "65%", md: "55%"}, top:{sm: "10%", md: "60%"}, transform:{sm: "translate(-65%,-10%)", md: "translate(-55%,-60%)"}, width: "50%", zIndex:"1"}}/>

              <Box sx={{position: "absolute", right:{sm: "50%", md: "-30%"}, top:{sm: "100%", md: "50%"}, transform:{sm: "translate(50%,-100%)", md: "translate(-30%,-50%)"}, width: {sm: "90%", md: "300px"}, zIndex:"3", color: "#fff"}}>
                <Text fontSize={{sm: "18px", md: "30px"}} fontWeight="700" textTransform="uppercase" lineHeight="1">Nora</Text>
                <Text fontSize={{sm: "9px", md: "11px"}} fontWeight="400" lineHeight="1.4">{texts.nora}</Text>
              </Box>

            </Box>

          </SwiperSlide>

          <SwiperSlide style={{display: "flex", alignItems: "center", overflow: "hidden"}}>

            <Box sx={{pos: "relative", w: {sm: "100%", md: "550px", xl: "650px", "2xl": "750px"}, aspectRatio: "1/1", mx: "auto"}}>
            
              <Img src="/assets/images/sections/heroes/ark1.webp" sx={{position: "absolute", left:{sm: "35%", md: "25%"}, top:{sm: "0%", md: "50%"}, transform:{sm: "translate(-25%,0%)", md: "translate(-35%,-50%)"}, width: "50%", zIndex:"1"}}/>

              <Img src="/assets/images/sections/heroes/ark2.webp" sx={{position: "absolute", left:{sm: "65%", md: "55%"}, top:{sm: "10%", md: "32.5%"}, transform:{sm: "translate(-65%,-10%)", md: "translate(-55%,-32.5%)"}, width: "25%", zIndex:"2"}}/>
              
              <Box sx={{position: "absolute", right:{sm: "50%", md: "-30%"}, top:{sm: "100%", md: "50%"}, transform:{sm: "translate(50%,-100%)", md: "translate(-30%,-50%)"}, width: {sm: "90%", md: "300px"}, zIndex:"3", color: "#fff"}}>
                <Text fontSize={{sm: "18px", md: "30px"}} fontWeight="700" textTransform="uppercase" lineHeight="1">Ark</Text>
                <Text fontSize={{sm: "9px", md: "11px"}} fontWeight="400" lineHeight="1.4">{texts.ark}</Text>
              </Box>

            </Box>

          </SwiperSlide>

          <SwiperSlide style={{display: "flex", alignItems: "center", overflow: "hidden"}}>

            <Box sx={{pos: "relative", w: {sm: "100%", md: "550px", xl: "650px", "2xl": "750px"}, aspectRatio: "1/1", mx: "auto"}}>
            
              <Img src="/assets/images/sections/heroes/animals2.webp" sx={{position: "absolute", left:{sm: "30%", md: "15%"}, top:{sm: "10%", md: "35%"}, transform:{sm: "translate(-30%,-10%)", md: "translate(-15%,-35%)"}, width: "22.5%", zIndex:"1"}}/>

              <Img src="/assets/images/sections/heroes/animals5.webp" sx={{position: "absolute", left:{sm: "20%", md: "0%"}, top:{sm: "40%", md: "65%"}, transform:{sm: "translate(-20%,-40%)", md: "translate(-0%,-65%)"}, width: "22.5%", zIndex:"3"}}/>

              <Img src="/assets/images/sections/heroes/animals1.webp" sx={{position: "absolute", left:{sm: "40%", md: "30%"}, top:{sm: "0%", md: "50%"}, transform:{sm: "translate(-40%,0%)", md: "translate(-30%,-50%)"}, width: "50%", zIndex:"2"}}/>
              
              <Img src="/assets/images/sections/heroes/animals4.webp" sx={{position: "absolute", left:{sm: "70%", md: "60%"}, top:{sm: "60%", md: "75%"}, transform:{sm: "translate(-70%,-75%)", md: "translate(-60%,-75%)"}, width: "25%", zIndex:"4"}}/>
              
              <Box sx={{position: "absolute", right:{sm: "50%", md: "-30%"}, top:{sm: "100%", md: "50%"}, transform:{sm: "translate(50%,-100%)", md: "translate(-30%,-50%)"}, width: {sm: "90%", md: "300px"}, zIndex:"5", color: "#fff"}}>
                <Text fontSize={{sm: "18px", md: "30px"}} fontWeight="700" textTransform="uppercase" lineHeight="1">Animals <br/> With Attitude</Text>
                <Text fontSize={{sm: "9px", md: "11px"}} fontWeight="400" lineHeight="1.4">{texts.animals}</Text>
              </Box>

            </Box>

          </SwiperSlide>

          <SwiperSlide style={{display: "flex", alignItems: "center", overflow: "hidden"}}>

            <Box sx={{pos: "relative", w: {sm: "100%", md: "550px", xl: "650px", "2xl": "750px"}, aspectRatio: "1/1", mx: "auto"}}>
            
              <Img src="/assets/images/sections/heroes/novus2.webp" sx={{position: "absolute", left:{sm: "50%", md: "40%"}, top:{sm: "15%", md: "40%"}, transform:{sm: "translate(-50%,-15%)", md: "translate(-40%,-40%)"}, width: "35%", zIndex:"1"}}/>
              
              <Img src="/assets/images/sections/heroes/novus3.webp" sx={{position: "absolute", left:{sm: "42.5%", md: "27.5%"}, top:{sm: "0%", md: "50%"}, transform:{sm: "translate(-42.5%,-0%)", md: "translate(-27.5%,-50%)"}, width: "60%", zIndex:"2"}}/>
              
              <Img src="/assets/images/sections/heroes/novus1.webp" sx={{position: "absolute", left:{sm: "25%", md: "15%"}, top:{sm: "60%", md: "85%"}, transform:{sm: "translate(-25%,-60%)", md: "translate(-15%,-85%)"}, width: "25%", zIndex:"3"}}/>
              
              <Box sx={{position: "absolute", right:{sm: "50%", md: "-30%"}, top:{sm: "100%", md: "50%"}, transform:{sm: "translate(50%,-100%)", md: "translate(-30%,-50%)"}, width: {sm: "90%", md: "300px"}, zIndex:"4", color: "#fff"}}>
                <Text fontSize={{sm: "18px", md: "30px"}} fontWeight="700" textTransform="uppercase" lineHeight="1">Novus</Text>
                <Text fontSize={{sm: "9px", md: "11px"}} fontWeight="400" lineHeight="1.4">{texts.novus}</Text>
              </Box>

            </Box>

          </SwiperSlide>

          <SwiperSlide style={{display: "flex", alignItems: "center", overflow: "hidden"}}>

            <Box sx={{pos: "relative", w: {sm: "100%", md: "550px", xl: "650px", "2xl": "750px"}, aspectRatio: "1/1", mx: "auto"}}>
            
              <Img src="/assets/images/sections/heroes/frank1.webp" sx={{position: "absolute", left:{sm: "50%", md: "35%"}, top:{sm: "0%", md: "50%"}, transform:{sm: "translate(-50%,0%)", md: "translate(-35%,-50%)"}, width: "50%", zIndex:"1"}}/>
              
              <Box sx={{position: "absolute", right:{sm: "50%", md: "-30%"}, top:{sm: "100%", md: "50%"}, transform:{sm: "translate(50%,-100%)", md: "translate(-30%,-50%)"}, width: {sm: "90%", md: "300px"}, zIndex:"2", color: "#fff"}}>
                <Text fontSize={{sm: "18px", md: "30px"}} fontWeight="700" textTransform="uppercase" lineHeight="1">Frank</Text>
                <Text fontSize={{sm: "9px", md: "11px"}} fontWeight="400" lineHeight="1.4">{texts.frank}</Text>
              </Box>

            </Box>

          </SwiperSlide>

        </Swiper>
        <Box pos="absolute" left="50%!important" bottom={{sm: "18%!important", md: "12.5%!important"}} transform={{sm: "translate(-50%, 18%)", md: "translate(-50%, 12.5%)"}} zIndex="2" display="flex" flexDir="column" alignItems="center">
          <Box className="heroes-swiper-pagination"
            sx={{
              "&":{
                width: "fit-content!important",
                height: "fit-content!important",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                "& > .swiper-pagination-bullet": {
                  pos: "relative",
                  opacity: "1",
                  bgColor: "transparent",
                  w: {sm: "12px", md: "14px"},
                  h: {sm: "12px", md: "14px"},
                  cursor: "pointer",
                  aspectRatio: "1",
                  mx: "0.65vw!important",
                  border: "1px solid transparent",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "100%",
                  "&:first-of-type": {
                    ml: "0!important",
                  },
                  "& > svg": {
                    w: {sm: "2px", md: "3px"},
                    h: {sm: "2px", md: "3px"},
                    transition: "200ms",
                    "& .head": {
                      fill: "#fff"
                    },
                    "& .eyes": {
                      fill: "transparent"
                    }
                  },
                  "&.swiper-pagination-bullet-active":{
                    border: "1px solid #f8bf13",
                    transition: "600ms",
                    "& > svg": {
                      w: {sm: "8px", md: "10px"},
                      h: {sm: "8px", md: "10px"},
                      "& .head": {
                        fill: "#f8bf13"
                      },
                      "& .eyes": {
                        fill: "#000"
                      },
                    }
                  },
                }
              },
            }}
          />
          <Box display="flex" fontSize="12px" mt="10px" color="#fff">
            <Text pos="relative" letterSpacing="6px">0{swiperActiveIndex} / 05</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SectionHeroes