import { FC, useState } from "react"
import { Link as LinkRRD } from "react-router-dom"

import SliderBlogs from "../sliderBlogs"
import SliderImages from "../sliderImages"

import { Box, Button, SystemStyleObject } from "@chakra-ui/react"
import { FaPlus as IconPlus } from "react-icons/fa"

import blogsData from "../../assets/data/blogs.js"

interface Props{
  className?: string
  sx?: SystemStyleObject
}

const SectionNews:FC<Props> = (props) => {

  const [firstSliderItems, setFirstSliderItems] = useState([
    {
      href: "blogs/",
      src: "assets/images/sections/news/1.webp"
    },
    {
      href: "blogs/",
      src: "assets/images/sections/news/2.webp"
    },
  ])
  
  return (
    <Box className={props.className} pos="relative" h="100vh" sx={props.sx}>

      <Box pos="absolute" top="50%" left="50%" transform="translate(-50%,-50%)">
        <Box pos="relative" h={{sm: "100vh", md: "400px"}} w={{sm: "82.5vw", md: "650px", lg: "850px", xl: "950px", "2xl": "1050px"}}>
          
          <Box
            sx={{
              pos: "absolute",
              left: {sm: "50%", md: "0%"},
              top: {sm: "15%", md: "50%"},
              transform: {sm: "translate(-50%, -15%)", md: "translate(0, -50%)"},
            }}
          >
            <SliderImages datas={firstSliderItems}/>
          </Box>

          <Box pos="absolute" left="50%" top="50%" w="1px" h="100%" sx={{transform: "translate(-50%, -50%)", "&::before, &::after": {content: "''", pos: "absolute", display: "block", bgColor: "#999", w: "1px", h: "45%"}, "&::before": {top: "0"}, "&::after": {bottom: "0"}}} display={{sm: "none", md: "block"}}/>

          <Box
            sx={{
              pos: "absolute",
              right: {sm: "auto", md: "0%"},
              top: {sm: "auto", md: "60%"},
              left: {sm: "50%", md: "auto"},
              bottom: {sm: "22.5%", md: "auto"},
              transform: {sm: "translate(-50%, 22.5%)", md: "translate(0, -60%)"},
              w: "fit-content", 
              h: "fit-content", 
              display: "flex", 
              flexDir: "column", 
              justifyContent: "center" 
            }}
          >
            <Box
              sx={{
                w: {sm: "85vw", md: "300px", lg: "400px", xl: "450px", "2xl": "500px"},
              }}
            >
              <SliderBlogs titles={["Latest", "Tales from Ark"]} datas={[blogsData]} showLast6/>
            </Box>
            <Button as={LinkRRD} to="/blogs" bgColor="#fff" color="#222" w="100px" h="25px" borderRadius="0px" px="0" mt="12px" ml="auto" fontSize="12px" _hover={{bgColor: "#ddd"}}>All News <IconPlus style={{marginLeft: "5px", fontSize: "11px"}}/></Button>
          </Box>
        </Box>
      </Box>
      
    </Box>
  )
}

export default SectionNews